import _ from 'lodash';
import store from 'store/store';
import { setInstitution } from 'store/actions';

// Import variables
import {
	SESSIONSTORAGE_INSTITUTION,
	PRODUCT_IMAGES_TYPES,
	EXTERNAL_LINKS
} from 'helpers/variables';

// Import assets
import ckfLogo from 'assets/icons/logo_ckf.svg';

// API-URL
const { REACT_APP_API_URL } = process.env;

const { COVER, LOGO } = PRODUCT_IMAGES_TYPES;
const { CKF_WEBSITE } = EXTERNAL_LINKS;

export const getInstitutionFromStorage = () => {
	const institution = sessionStorage.getItem(SESSIONSTORAGE_INSTITUTION);

	return JSON.parse(institution);
};

// set institution from sessionStorage to redux
export const setInstitutionToRedux = () => {
	const institution = getInstitutionFromStorage();
	institution && setInstitution(institution)(store.dispatch);
};

export const getInstitutionAssets = ({ assets, uuid = '' }) => {
	const coverAsset = assets?.find(({ collection }) => collection === COVER);
	const logoAsset = assets?.find(({ collection }) => collection === LOGO);

	const coverUrl = coverAsset?.url || '';
	const logoUrl = logoAsset?.url || `${REACT_APP_API_URL}/assets/${uuid}/logo`;

	return { coverUrl, logoUrl };
};

export const getInstitutionData = ({ uuid, url, name, assets }) => {
	const { logoUrl } = getInstitutionAssets({ assets, uuid });

	let institutionLogo = ckfLogo;
	let institutionWebsite = CKF_WEBSITE;
	let institutionName = 'common_full_app_name';
	let isNameTranslation = true;

	if (uuid) {
		institutionLogo = logoUrl;
		institutionWebsite = url;
		institutionName = name;
		isNameTranslation = false;
	}

	return {
		institutionLogo,
		institutionWebsite,
		institutionName,
		isNameTranslation
	};
};

export const sortInstitutions = (list) => {
	if (list?.length) {
		const mainInstitution = list.find(({ is_main }) => !!is_main);

		const filteredInstitutions = list.filter(
			({ uuid }) => uuid !== mainInstitution?.uuid
		);

		const sortedInstitutions = _.sortBy(filteredInstitutions, 'name');

		const institutions = mainInstitution
			? [mainInstitution, ...sortedInstitutions]
			: sortedInstitutions;

		return institutions;
	}

	return [];
};
