import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const AuthButtonStyled = styled(Button)`
	margin-top: 3rem;
	width: 26rem;
	&:hover {
		color: ${({ theme }) => theme.globalButtons.primary.hoverColor};
	}
`;
