import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

// Import helpers
import { QUERY_PAYMENT_RESOURCES, PRODUCT_TYPES_MAPPED } from 'helpers';
import {
	fetchSubscriberPromotions,
	fetchLastGateway,
	prepareResourcesResponse,
	RESOURCES_NAMES,
	fetchProductPrices,
	fetchProductDetails,
	filterPaymentMethods
} from './helpers';

// Import actions
import { clearPurchase, clearPayment, fetchUserDetails } from 'store/actions';

// Import context actions
import {
	setUserPromotions,
	setPaymentMethods,
	setInvoiceAgreement
} from '../store/actions';

const useResources = ({ state, stateDispatch }) => {
	const dispatch = useDispatch();
	const { id: productId, type: subtype } = useParams();

	// ---- redux data ----
	const { email_approved, payment_methods: userPaymentMethods } = useSelector(
		({ auth }) => auth.authenticate
	);

	// ---- variables ----
	const { paymentMethods } = state;

	const isPaymentValid = !!paymentMethods.length;

	// ---- handlers ----
	const handleSetPaymentMethods = (data) => {
		// filter methods
		const methods = filterPaymentMethods({ data, userPaymentMethods });
		// set payment methods
		setPaymentMethods(methods)(stateDispatch);
	};

	const handleFetchResources = async () => {
		// fetch user details to check if email is approved
		!email_approved && (await fetchUserDetails()(dispatch));
	};

	const handleClearResources = () => {
		// Dispatch an clearPayment action in payment_actions folder
		clearPayment(dispatch);
		// Dispatch an clearPurchases action in purschase_actions folder
		clearPurchase(dispatch);
	};

	const handleFetchResourcesSuccess = (data) => {
		const { productPrices, subscriberPromotions } = data;
		// set payment methods
		handleSetPaymentMethods(productPrices);
		// set user available promotions
		setUserPromotions(subscriberPromotions)(stateDispatch);
	};

	const handleSetInvoiceAgreement = () => setInvoiceAgreement()(stateDispatch);

	const productType = PRODUCT_TYPES_MAPPED[subtype];

	const resourcesFunctions = [
		{
			name: RESOURCES_NAMES.PRODUCT_PRICES,
			fetchFunction: () =>
				fetchProductPrices({ id: productId, type: productType })
		},
		{
			name: RESOURCES_NAMES.PRODUCT_DETAILS,
			fetchFunction: () =>
				fetchProductDetails({ id: productId, type: productType })
		},
		{
			name: RESOURCES_NAMES.SUBSCRIBER_PROMOTIONS,
			fetchFunction: () => fetchSubscriberPromotions()
		},
		{
			name: RESOURCES_NAMES.LAST_GATEWAY,
			fetchFunction: () => fetchLastGateway()
		}
	];

	const fetchResources = async () => {
		const response = await Promise.all(
			resourcesFunctions.map(({ fetchFunction }) => fetchFunction())
		);

		return prepareResourcesResponse({ response, resourcesFunctions });
	};

	const { isLoading, isError, data, remove } = useQuery({
		queryKey: QUERY_PAYMENT_RESOURCES,
		queryFn: fetchResources,
		onSuccess: handleFetchResourcesSuccess
	});

	const resourcesData = data || {};

	// ---- effect ----
	useEffect(() => {
		handleFetchResources();
		return () => handleClearResources();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'smooth'
		});

		return () => remove();
		// eslint-disable-next-line
	}, []);

	return {
		setPaymentMethods: handleSetPaymentMethods,
		isPaymentValid,
		isResourcesLoading: isLoading,
		isResourcesError: isError,
		productId,
		setInvoiceAgreement: handleSetInvoiceAgreement,
		...resourcesData
	};
};

export default useResources;
