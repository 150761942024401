import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { flex, transitionMain } from 'components/styles';

const iconNavBase = (theme) => css`
	${flex()}
	margin: 0 2rem;
	padding: 0;
	svg {
		height: 2.4rem;
		width: 2.4rem;
		path {
			fill: ${theme.body.nav.icon};
		}
	}
`;

const BUTTON_MODIFIERS = {
	default: ({
		theme: {
			globalButtons: { primary }
		}
	}) => css`
		background-color: ${primary.bgColor};
		border-color: ${primary.borderColor};
		color: ${primary.color};

		&:hover,
		&:focus {
			background-color: ${primary.hoverBgColor};
			border-color: ${primary.hoverBorderColor};
			box-shadow: 0 0 4px ${primary.hoverBorderColor};
			color: ${primary.hoverColor};
		}
		&:focus-visible {
			background-color: ${primary.focusBgColor};
			border-color: ${primary.hoverBorderColor};
			box-shadow: 0 0 4px ${primary.hoverBorderColor};
			color: ${primary.hoverColor};
		}
	`,
	secondary: ({
		theme: {
			globalButtons: { secondary }
		}
	}) => css`
		background-color: ${secondary.bgColor};
		border-color: ${secondary.borderColor};
		color: ${secondary.color};

		&:hover,
		&:focus {
			background-color: ${secondary.hoverBgColor};
			border-color: ${secondary.hoverBorderColor};
			box-shadow: 0 0 4px ${secondary.hoverBorderColor};
			color: ${secondary.hoverColor};
		}
		&:focus-visible {
			background-color: ${secondary.focusBgColor};
			border-color: ${secondary.hoverBorderColor};
			box-shadow: 0 0 4px ${secondary.hoverBorderColor};
			color: ${secondary.hoverColor};
		}
	`,
	dropdown: ({ theme }) => css`
		${flex({ jc: 'space-between' })}

		background: ${theme.dropdown.button.bg};
		border-color: ${theme.line};
		color: ${theme.dropdown.button.color};
		text-align: left;

		&:hover {
			color: ${theme.dropdown.button.colorHover};
		}
	`,
	icon: () => css`
		background-color: transparent;
		padding: 0;
	`,

	primaryVod: ({ theme, cancel, isNotAvailable }) => css`
		border-radius: ${theme.player.box.button.roundSize}rem;
		color: ${theme.player.box.button.color};
		border: 1px solid ${theme.player.box.button.border};
		background: ${theme.player.box.button.bgColor};
		width: 12.7rem;
		padding: 1.15rem 0;
		font-size: ${theme.fontSize.player.box.button}rem;
		position: relative;
		z-index: 10;
		overflow: hidden;
		transition: all 0.2s ease-in-out;

		${isNotAvailable &&
		css`
			cursor: not-allowed;
		`}

		${cancel &&
		css`
			border: 1px solid ${theme.player.box.button.cancelBorder};
			background: ${theme.player.box.button.cancelBgColor};
			color: ${theme.player.box.button.cancelColor};
		`}
	`,

	playerIconHover: ({ theme }) => css`
		&:hover {
			svg {
				path,
				text {
					fill: ${theme.player.buttonHover};
				}
				circle {
					stroke: ${theme.player.buttonHover};
				}
			}
		}

		&:focus {
			svg {
				path,
				text {
					fill: ${theme.player.buttonHover};
				}
				circle {
					stroke: ${theme.player.buttonHover};
				}
			}
		}

		&:focus-visible {
			svg {
				path,
				text {
					fill: ${theme.player.buttonHover};
				}
				circle {
					stroke: ${theme.player.buttonHover};
				}
			}
		}
	`,

	playerPlay: () => css`
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
	`,
	navIcon: ({ theme }) => css`
		${iconNavBase(theme)}

		&:hover {
			color: ${theme.body.nav.iconActive};

			svg {
				path {
					fill: ${theme.body.nav.iconActive};
				}
			}
		}

		&:focus-visible {
			color: ${theme.body.nav.iconActive};

			svg {
				path {
					fill: ${theme.body.nav.iconActive};
				}
			}
		}
	`,
	pipIcon: ({ theme, isAvailable }) => css`
		${iconNavBase(theme)}
		cursor: ${isAvailable ? 'pointer' : 'not-allowed'};
		svg {
			path {
				fill: ${isAvailable ? theme.primary : theme.grayMedium};
			}
		}
	`,
	parental: ({ theme }) => css`
		width: 19rem;
		height: 5rem;
		font-size: ${theme.fontSize.player.parental.button}rem;
		font-weight: ${theme.font.medium};
		color: ${theme.player.parental.button.color};
		border: 1px solid ${theme.player.parental.button.border};
		border-radius: ${theme.player.parental.button.roundSize}rem;
		background: ${theme.player.parental.button.bgColor};
	`,
	userProfile: ({ marginBottom, theme }) => css`
		font-size: ${theme.fontSize.xs}rem;
		padding: 0 4.8rem;
		height: 5rem;
		font-weight: ${({ theme }) => theme.font.bold};
		border: 3px solid transparent;
		&:not(:last-child) {
			margin-bottom: ${marginBottom ? marginBottom : '0'};
			margin-right: 1.4rem;
		}
	`,
	voucher: ({ theme: { payment } }) => css`
		background-color: ${payment.voucher.submit.background};
		color: ${payment.voucher.submit.color};
		border: none;
		padding: 0 2rem;
		height: 100%;
		border-radius: ${payment.voucher.submit.roundSize}rem;
		transition: outline 0s, background 0.3s ease-in-out;
	`,
	mobile: ({ theme: { globalButtons } }) =>
		css`
			background: none;
			border: none;
			color: ${globalButtons.mobile.color};

			&:focus,
			&:hover {
				color: ${globalButtons.mobile.colorHover};
			}
		`,
	showAll: ({ theme: { globalButtons } }) => css`
		background: none;
		color: ${globalButtons.showAll.color};

		svg {
			transform: translate(30%, 25%);
			fill: ${globalButtons.showAll.color};
		}

		&:focus,
		&:hover {
			color: ${globalButtons.showAll.colorHover};

			svg {
				path {
					fill: ${globalButtons.showAll.colorHover};
				}
			}
		}
	`
};

const Button = styled.button`
	padding: ${({ padding }) => padding || '1rem 1.2rem'};
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	line-height: 2.4rem;
	border-radius: ${({ theme }) => theme.globalButtons.roundSize}rem
	font-weight: ${({ theme }) => theme.font.bold};
	cursor: ${({ isFocus = true }) => (isFocus ? 'pointer' : 'none')};
	border: 3px solid transparent;
	user-select: none;
	width: ${({ width = 'auto' }) => width};
	background-color: transparent;
	${transitionMain}
	${flex()};

	svg {
		path {
			${transitionMain}
		}
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	${({ isCatchupControllers }) =>
		isCatchupControllers &&
		css`
			svg {
				height: 3rem;
				width: 3rem;
			}
		`}


	${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export default Button;
