import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'components/theme/GlobalStyle';

// Import styled components
import { Container } from './styles';

// Import custom hooks
import { useAppTemplate } from './useAppTemplate';

// Import components
import PortalUnavailable from 'components/views/PortalUnavailable';
import HelmetMetaTags from 'components/layout/helmet/HelmetMetaTags';
import Content from './Content';
import Analitics from './Analitics';

const AppTemplate = ({ children }) => {
	const { isPortalAvailable, theme, isFunctionalCookieAccepted } =
		useAppTemplate();

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			{isPortalAvailable ? (
				<Container>
					<HelmetMetaTags />
					{isFunctionalCookieAccepted && <Analitics />}
					<Content>{children}</Content>
				</Container>
			) : (
				<PortalUnavailable />
			)}
		</ThemeProvider>
	);
};

AppTemplate.propTypes = {
	children: PropTypes.array.isRequired
};

export default AppTemplate;
