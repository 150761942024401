import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';
export const InputStyled = styled(Input)`
	height: 4.4rem;
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	padding: 1rem 2rem;

	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
		color: ${({ theme }) => theme.authentication.input.focusColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.error.border};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		transition: background-color 5000s ease-in-out 0s;
	}
`;
