import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Routes
import AppRoutes from 'components/routes/app_routes/AppRoutes';

// Helpers
import { saveAffiliateClientData, getSearchParametr } from 'helpers';

// Templates
import { AppTemplate, ContentTemplate } from 'components/templates';

// Layout
import Header from 'components/layout/header/Header';
import Footer from 'components/layout/footer/Footer';

// Import logic
import useApp from './useApp';

// Import styles
import { Wrapper } from './styles';

// Import variables
import { SECTIONS_ID, AFFILIATE_CLIENT } from 'helpers/variables';
import { APP_CORE_PATHS } from 'components/routes/paths';

const { CONTENT } = SECTIONS_ID;
const { EPG } = APP_CORE_PATHS;

const App = () => {
	const { isMainNavVisible, isFooterVisible } = useApp();
	const { search, pathname } = useLocation();

	const isEPG = pathname.includes(EPG);

	useEffect(() => {
		// Save affiliate client data locally
		const affiliateID = getSearchParametr(search, AFFILIATE_CLIENT);
		affiliateID && saveAffiliateClientData(affiliateID);

		// eslint-disable-next-line
	}, []);

	return (
		<AppTemplate>
			<Header showMainNav={isMainNavVisible} />
			<Wrapper id={CONTENT} isEPG={isEPG}>
				<ContentTemplate>
					<AppRoutes />
				</ContentTemplate>
			</Wrapper>
			{isFooterVisible && <Footer />}
		</AppTemplate>
	);
};

export default App;
