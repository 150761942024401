import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import services from 'services/services';
import { fetchActiveSubscriptionsList } from 'store/actions';

// Import helpers
import { getPaymentLink } from 'helpers';
import { POST_SUBSCRIPTION_DEACTIVATE_API } from 'helpers/api';
import { openNotification } from 'components/utilities/notification/notification';

const useSubscriptionManage = ({ subscriptionId, priceId = null } = {}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { data: product } = useSelector(({ movieDetails }) => movieDetails);
	const { data: subscriptions } = useSelector(
		({ activeSubscriptions }) => activeSubscriptions
	);

	const {
		product_title: title,
		product_subtype: subtype,
		product_uuid: uuid,
		id,
		status
	} = subscriptions.find(({ id, product_uuid }) => {
		return id === subscriptionId || product_uuid === product?.uuid;
	});

	const handleRedirectToPayment = () => {
		const redirectData = getPaymentLink({
			id: uuid,
			type: subtype,
			title,
			priceId,
			isSubscriptionUpdate: true
		});

		history.push(redirectData);
	};

	const handleFetchSubscriptions = () =>
		fetchActiveSubscriptionsList()(dispatch);

	const deactivatedSubscriptionId = subscriptionId || id;

	const handleDeactivateSubscription = () => {
		const url = `${POST_SUBSCRIPTION_DEACTIVATE_API}/${deactivatedSubscriptionId}`;
		return services.post(url);
	};

	const handleDeactivateError = () => {
		openNotification({
			type: 'error',
			title: t('vod_root_error_title'),
			description: t('common_subscription_error_description')
		});
	};

	const { mutateAsync: onSubscriptionCancel, isLoading: isDeactivateLoading } =
		useMutation(handleDeactivateSubscription, {
			onError: handleDeactivateError,
			onSuccess: handleFetchSubscriptions
		});

	return {
		isDeactivateLoading,
		status,
		product,
		onSubscriptionCancel: onSubscriptionCancel,
		redirectToPayment: handleRedirectToPayment
	};
};

export default useSubscriptionManage;
