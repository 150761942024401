import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';
import axios from 'axios';
import { setSubscriberInLocal } from 'store/actions';
import * as types from 'store/actions/types';

// Import variables
import { PLAYER_SELECTORS, LOCALSTORAGE_PLAYER } from 'helpers/variables';
import { HTTP_STATUS_CODES, getCodeErrorMessage } from 'helpers/error_code_api';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { createPlayerPlaylist } from 'helpers/player';
import { getAffiliateClientQuery } from 'helpers/affiliate_client';

import {
	setActionType,
	setPlayerUrlConfiguration,
	getErrorCodeMessage,
	getAvailableChannel,
	setConfig
} from './player_helpers/index';

let source = axios.CancelToken.source();

// ******************** FETCH PLAYER CONFIGURATION ********************
export const fetchPlayerConfig =
	({
		productID = null,
		type = 'player',
		isCatchup = false,
		videoType = 'channel',
		programID = null,
		videoID = null,
		isAuth = false
	} = {}) =>
	async (dispatch, getState) => {
		try {
			source = axios.CancelToken.source();

			const { loadingType, successType } = setActionType(type);
			// Dispatch an loading action
			dispatch({
				type: loadingType,
				payload: true
			});

			let live = null;
			if (videoType === 'channel') {
				const {
					channels: { data }
				} = getState();
				live = getAvailableChannel(data);
			}

			const isProductID = videoType === 'channel' ? live.uuid : productID;

			// Set current product id to local storage
			const playerStorage = JSON.parse(
				localStorage.getItem(LOCALSTORAGE_PLAYER)
			);
			videoType !== 'vod' &&
				localStorage.setItem(
					LOCALSTORAGE_PLAYER,
					JSON.stringify({ ...playerStorage, productID: isProductID })
				);

			const url = setPlayerUrlConfiguration({
				productID: isProductID,
				isCatchup,
				programID,
				videoID,
				videoType
			});

			// Get config
			const { data: config } = await services.get(url, {
				cancelToken: source.token
			});

			// Check if user is in local
			isAuth && setSubscriberInLocal()(dispatch, getState);

			// Get video session
			const { videoSessionId } = config.videoSession;

			// Create player playlist
			const playlist = createPlayerPlaylist({
				id: isProductID,
				videoSessionId
			});

			// Dispatch an action
			dispatch({
				type: successType,
				payload: { config: setConfig(config), playlist, videoSessionId }
			});
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage, errorType, code, errorCode } =
					getErrorCodeMessage(type, error);
				// Dispatch an error action
				dispatch({
					type: errorType,
					payload: { code, errorCode, message: codeErrorMessage }
				});
			}
		}
	};

// ******************** UPDATE WATCHING IN PROGRESS ********************
export const updateWatchingInProgress =
	({ time, productUuid }) =>
	async () => {
		try {
			// Get AffiliateClient ID query
			const query = getAffiliateClientQuery();

			// Delete video session
			await services.post(`/subscriber/bookmarks/watching/update${query}`, {
				productUuid,
				time
			});
		} catch (error) {
			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					duration: 3,
					title: i18next.t('player_error_update_watching_error')
				});
		}
	};

// ******************** UPDATE VIDEO SESSION ********************
export const updateVideoSession =
	({ videoSessionId, type }, erorrCallback) =>
	async (dispatch) => {
		try {
			// Update video session
			await services.put(`/player/videosession/${videoSessionId}`);
		} catch (error) {
			const isOnline = navigator.onLine;
			const invalidSessionMessage = getCodeErrorMessage(
				'VIDEO_SESSION_INVALID'
			);
			const { NOT_ACCEPTABLE } = HTTP_STATUS_CODES;

			if (error.response) {
				const { codeErrorMessage, errorType, code, status } =
					getErrorCodeMessage(type, error);

				let playerErrorMessage = codeErrorMessage;

				// check if error status is '406 Not Acceptable'
				const isNotAcceptableStatus = status === NOT_ACCEPTABLE;

				// destroy player if user is online or error status is '406 Not Acceptable'
				if (isOnline || isNotAcceptableStatus) {
					erorrCallback();
				}

				// if error status is '406 Not Acceptable'
				// set player error message as VIDEO_SESSION_INVALID
				if (isNotAcceptableStatus) {
					playerErrorMessage = invalidSessionMessage;
				}

				// Dispatch an error action
				if (isOnline) {
					dispatch({
						type: errorType,
						payload: {
							code: code || status,
							message: playerErrorMessage
						}
					});
				}
			} else if (isOnline) {
				const { errorType } = setActionType(type);

				// if error does not contain response but user is online
				// destroy player and set error message as VIDEO_SESSION_INVALID

				erorrCallback();

				dispatch({
					type: errorType,
					payload: {
						message: invalidSessionMessage
					}
				});
			}
		}
	};

// ******************** DELETE VIDEO SESSION ********************
export const deleteVideoSession =
	({ type = 'player' } = {}) =>
	async (dispatch, getState) => {
		try {
			const { deleteSuccessType } = setActionType(type);

			const state = getState();
			const { videoSessionId } = state[type];

			if (videoSessionId) {
				// Delete video session
				await services.delete(`/player/videosession/${videoSessionId}`);

				// Dispatch an action
				dispatch({
					type: deleteSuccessType,
					payload: true
				});
			}
		} catch (error) {
			const { deleteErrorType } = setActionType(type);
			// Dispatch an error action
			dispatch({
				type: deleteErrorType,
				payload: error.response
			});
		}
	};

// ******************** PLYAER PLAYBACK ERROR ********************
export const setPlayerPlaybackError = (
	error,
	type = PLAYER_SELECTORS.PLAYER
) => {
	const { playerErrorType } = setActionType(type);
	return {
		type: playerErrorType,
		payload: { code: error.code, message: error.message }
	};
};

// ******************** PLYAER IS READY ********************
export const setPlayerReady = ({ isReady = true, type }) => {
	const { readyType } = setActionType(type);
	return {
		type: readyType,
		payload: isReady
	};
};

// ******************** SET PLAYER CUSTOM ERROR ********************
export const setPlayerCustomError = (error, playerType) => (dispatch) => {
	const { codeErrorMessage, errorType, code, errorCode } = getErrorCodeMessage(
		playerType,
		error
	);
	// Dispatch an error action
	dispatch({
		type: errorType,
		payload: { code, errorCode, message: codeErrorMessage }
	});
};

// ******************** CLEAR PLAYER DATA ********************
export const playerOnCancel =
	(type = 'player') =>
	(dispatch) => {
		const { deleteLoadingType } = setActionType(type);

		// Cancel config request
		source.cancel();

		// Dispatch an action
		dispatch({
			type: deleteLoadingType,
			payload: true
		});
	};

// ******************** CLEAR PLAYER PLAYBACK ERROR ********************
export const clearPlayerPlaybackError = (type = PLAYER_SELECTORS.PLAYER) => {
	const { clearPlaybackError } = setActionType(type);
	return {
		type: clearPlaybackError
	};
};

// ******************** SET PLAYER TV OPEN ********************
export const setPlayerTvOpen = (isPlayerTvOpen) => ({
	type: types.SET_PLAYER_TV_OPEN,
	payload: isPlayerTvOpen
});
