import { useLocation } from 'react-router-dom';

// Import helpers
import { checkIsDesktopOnly, getUserAgentInfo } from 'helpers';
import { LOGIN_ROUTES } from './helpers';

const useApp = () => {
	const location = useLocation();
	const { pathname } = location;
	const { isMobile } = getUserAgentInfo();

	const isDesktopOnly = checkIsDesktopOnly();
	const isLoginRoute = LOGIN_ROUTES.some((item) => pathname.includes(item));

	const isBlockedByMobileDevices = isDesktopOnly && isMobile;

	const isFooterVisible = !isBlockedByMobileDevices;

	const isMainNavVisible = !isBlockedByMobileDevices && !isLoginRoute;

	return { isMainNavVisible, isFooterVisible };
};

export default useApp;
