import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
// Import utilties
import { customMinResponsive, size } from 'components/styles';

export const Nav = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	color: ${({ theme: { body } }) => body.footer.link};
`;

export const Item = styled.li`
	margin-bottom: 1.2rem;
	margin-left: 2.5rem;
	padding: 0 1.45rem;
	height: auto;
	position: relative;
	color: ${({ theme: { body } }) => body.footer.color};

	${customMinResponsive(
		400,
		css`
			height: auto;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 1.8rem;
			margin-bottom: 0;
			margin-left: 0;
		`
	)}

	&:last-child {
		padding-right: 0;

		&:after {
			display: none;
		}
	}
`;

export const linkStyle = css`
	&:link,
	&:visited {
		color: ${({ theme: { body } }) => body.footer.color};
		text-decoration: none;
		font-size: ${({ theme }) => theme.fontSize.footer.media.link}rem;
		font-weight: ${({ theme }) => theme.font.medium};
		line-height: 2.3rem;
		transition: 0.2s ease-in-out;
	}

	&:hover {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:active {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:focus-visible {
		color: ${({ theme: { body } }) => body.footer.hover};
	}
`;

export const StyledLink = styled(RouterLink)`
	${linkStyle}
`;
