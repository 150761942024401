import React from 'react';

// Import components
import DesktopOnlyRoutes from './DesktopOnlyRoutes';
import Routes from './Routes';

// Import helpers
import { checkIsDesktopOnly } from 'helpers';

const AppRoutes = () => {
	const isDesktopOnly = checkIsDesktopOnly();

	return isDesktopOnly ? <DesktopOnlyRoutes /> : <Routes />;
};

export default AppRoutes;
