import React from 'react';
import { object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import {
	AuthModal,
	ProductPrice,
	PaymentProtect,
	CommonButton
} from 'components/elements';

// Import logic
import usePaymentButton from './usePaymentButton';

const PaymentButton = (props) => {
	const { t } = useTranslation();

	const {
		theme,
		regular,
		promotion,
		redirectData,
		buttonText,
		isAvailable,
		isDisabled,
		isPaymentPending,
		isLoading,
		isLimitExceeded,
		limitMessage,
		renderAs,
		periodText
	} = usePaymentButton(props);

	if (!isAvailable) return null;

	const renderButtonContent = () => {
		if (isLoading || isPaymentPending) {
			return t('vod_buttons_subscription_verification');
		} else if (isLimitExceeded) {
			return limitMessage;
		}

		return (
			<>
				{t(buttonText)}
				<ProductPrice
					regular={regular}
					promotion={promotion}
					oldPriceColor={theme.payment.oldPrice}
				/>
				{periodText && <span>{periodText}</span>}
			</>
		);
	};

	return (
		<AuthModal>
			<PaymentProtect>
				<CommonButton
					width={238}
					isDisabled={isDisabled}
					as={renderAs}
					to={redirectData}
					isLoading={isLoading}
					isPrime
					loaderColor="secondary"
				>
					{renderButtonContent()}
				</CommonButton>
			</PaymentProtect>
		</AuthModal>
	);
};

PaymentButton.defaultProps = {
	isSubscriptionUpdate: false
};

PaymentButton.propTypes = {
	product: object.isRequired,
	isSubscriptionUpdate: bool
};

export default PaymentButton;
