import styled from 'styled-components';

const WatermarkImage = styled.img`
	width: 6.95vw;
	min-width: 10rem;
	opacity: 0.7;
	filter: grayscale(${({ isContrast }) => (isContrast ? 1 : 0)});
`;

export default WatermarkImage;
