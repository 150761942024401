import React from 'react';
import { useTranslation } from 'react-i18next';
import { node } from 'prop-types';

// Import icons
import { ReactComponent as IconTick } from 'assets/icons/ic_back.svg';

// Import logic
import useProductPaymentTemplate from './useProductPaymentTemplate';

// Import styles
import { Container, BackButton, ErrorInfo } from './styles';

// Import components
import { Loader } from 'components/elements';
import PaymentError from './PaymentError';

const ProductPaymentTemplate = ({ children }) => {
	const { t } = useTranslation();

	const {
		isPaymentError,
		paymentErrorMessage,
		isPurchaseError,
		purchaseErrorMessage,
		showLoader,
		historyGoBack,
		isResourcesError,
		isPaymentValid
	} = useProductPaymentTemplate();

	return (
		<Container isError={isResourcesError || !isPaymentValid}>
			<BackButton
				onClick={historyGoBack}
				title={t('payment_button_back')}
				aria-label={t('payment_button_back')}
			>
				<IconTick aria-hidden="true" />
			</BackButton>

			{showLoader && <Loader zIndex={900} position="absolute" background />}

			{isPaymentError && <PaymentError message={paymentErrorMessage} />}

			{isPurchaseError && <PaymentError message={purchaseErrorMessage} />}

			{isResourcesError ? (
				<ErrorInfo>{t('common_errors_connection')}</ErrorInfo>
			) : (
				children
			)}
		</Container>
	);
};

ProductPaymentTemplate.propTypes = {
	children: node.isRequired
};

export default ProductPaymentTemplate;
