import styled, { css, keyframes } from 'styled-components';

// Import styles
import { transitionMain } from 'components/styles';

// Delay of pointer events on the slider arrow
// In combination with opacity solves unwanted slider item hover
const delayPointerEventsBlock = keyframes`
    99% {
        pointer-events: none;
    }
    100% {
        pointer-events: none;
    }
`;

export const ArrowController = styled.button`
	position: absolute;
	bottom: 0;
	z-index: 10;
	height: 100%;
	width: max-content;
	display: flex !important;

	&.slick-disabled {
		animation: ${delayPointerEventsBlock} 1000ms forwards;
		opacity: 0;
		cursor: default !important;
	}

	&::before {
		display: none !important;
		animation: ${delayPointerEventsBlock} 1000ms forwards;
		cursor: default !important;
	}

	svg {
		display: block;
		height: ${({ slideHeight }) => slideHeight}px;

		path {
			stroke: ${({ theme }) => theme.slider.arrow.color};
			fill: ${({ theme }) => theme.slider.arrow.color};
			${transitionMain}
		}
		circle,
		ellipse {
			fill: ${({ theme }) => theme.slider.arrow.bgColor};
		}
	}

	&:focus-visible {
		svg {
			path {
				stroke: ${({ theme }) => theme.slider.arrow.focus};
				fill: ${({ theme }) => theme.slider.arrow.focus};
			}
			circle,
			ellipse {
				fill: ${({ theme }) => theme.slider.arrow.bgFocusColor};
			}
		}
	}
	&:hover {
		cursor: pointer;

		svg {
			path {
				stroke: ${({ theme }) => theme.slider.arrow.hover};
				fill: ${({ theme }) => theme.slider.arrow.hover};
			}
			circle,
			ellipse {
				fill: ${({ theme }) => theme.slider.arrow.bgHoverColor};
			}
		}
	}

	${({ isPrevArrow }) =>
		isPrevArrow
			? css`
					left: 0;
					svg {
						margin-left: 1rem;
					}
			  `
			: css`
					right: 0;
					svg {
						margin-right: 1rem;
					}
			  `};
`;
