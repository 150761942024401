import React from 'react';
import { string, number, node, bool, func, object } from 'prop-types';

// Import components
import PaymentOptionPrice from './PaymentOptionPrice';
import {
	Container,
	Name,
	Bottom,
	ArrowButton,
	FlexContainer,
	IconArrowRight
} from './styles';

// Import variables
import { GATEWAY_TYPES } from 'helpers/prices';

const { OTHER_BLUEMEDIA } = GATEWAY_TYPES;

const PaymentOption = ({
	price,
	paymentName,
	children,
	addBottomWrapper,
	promotion,
	type,
	onButtonClick,
	isDisabled
}) => {
	const isOtherBluemedia = type === OTHER_BLUEMEDIA;

	return (
		<Container>
			<Name id={type} isDesktop>
				{paymentName}
			</Name>
			<FlexContainer isOtherBluemedia={isOtherBluemedia}>
				{isOtherBluemedia && <Name isDesktop={false}>{paymentName}</Name>}
				<PaymentOptionPrice price={price} promotion={promotion} />
				<ArrowButton disabled={isDisabled} onClick={onButtonClick}>
					<IconArrowRight />
				</ArrowButton>
			</FlexContainer>
			{addBottomWrapper ? <Bottom>{children}</Bottom> : children}
		</Container>
	);
};

PaymentOption.defaultProps = {
	price: null,
	paymentName: '',
	addBottomWrapper: true,
	onButtonClick: () => {},
	isDisabled: false
};

PaymentOption.propTypes = {
	price: number,
	paymentName: string.isRequired,
	children: node.isRequired,
	addBottomWrapper: bool,
	type: string.isRequired,
	onButtonClick: func.isRequired,
	isDisabled: bool.isRequired,
	promotion: object
};

export default PaymentOption;
