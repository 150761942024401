import React from 'react';
import { string } from 'prop-types';

// Import componens
import { SliderSection, Loader } from 'components/elements';
import { Container } from './styles';

// Import logic
import usePacketSlider from './usePacketSlider';

const PacketSlider = ({ sliderTitle }) => {
	const { isLoading, products } = usePacketSlider();

	return isLoading ? (
		<Loader position="static" background={false} />
	) : (
		<Container>
			<SliderSection
				showAll={false}
				sliderTitle={sliderTitle}
				content={products}
				modifiers="collection"
			/>
		</Container>
	);
};

PacketSlider.propTypes = {
	sliderTitle: string
};

export default PacketSlider;
