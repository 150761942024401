import React from 'react';

// Import components
import FooterNav from './FooterNav';
import FooterSocial from './FooterSocial';
import Copyrights from './Copyrights';
import Languages from './Languages';
import PaymentInfo from './PaymentInfo';
import PortalLogo from 'components/layout/nav/portal_logo/PortalLogo';
import AdditionalInfo from './AdditionalInfo';

// Import styles
import {
	FooterStyled,
	Wrapper,
	InnerContainer,
	FlexContainer,
	NavWrapper
} from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { FOOTER } = SECTIONS_ID;

const Footer = () => {
	const showLanguages = false;

	return (
		<FooterStyled id={FOOTER}>
			<Wrapper>
				<FlexContainer>
					<NavWrapper>
						<PortalLogo />
						<FooterNav />
					</NavWrapper>
					<FooterSocial />
					<InnerContainer>
						{showLanguages && <Languages />}
						<PaymentInfo />
						<AdditionalInfo />
					</InnerContainer>
				</FlexContainer>
				<Copyrights />
			</Wrapper>
		</FooterStyled>
	);
};

export default Footer;
