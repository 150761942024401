import React from 'react';
import { useTranslation } from 'react-i18next';

// Import images
import { ReactComponent as Instagram } from 'assets/icons/ic_instagram.svg';
import { ReactComponent as Facebook } from 'assets/icons/ic_facebook.svg';

// Import styled components
import { Social, AnchorStyled, LinkedinStyled } from './styles';

// Import variables
import { EXTERNAL_LINKS } from 'helpers/variables';

const { FACEBOOK, LINKEDIN, INSTAGRAM } = EXTERNAL_LINKS;

const FooterSocial = () => {
	const { t } = useTranslation();
	return (
		<Social>
			<AnchorStyled
				target="_blank"
				rel="noopener noreferrer"
				href={FACEBOOK}
				aria-label={t('aria_footer_social_media_facebook')}
				title={t('aria_footer_social_media_facebook')}
			>
				<Facebook aria-hidden="true" />
			</AnchorStyled>
			<AnchorStyled
				target="_blank"
				rel="noopener noreferrer"
				href={INSTAGRAM}
				aria-label={t('aria_footer_social_media_instagram')}
				title={t('aria_footer_social_media_instagram')}
			>
				<Instagram aria-hidden="true" />
			</AnchorStyled>
			<AnchorStyled
				target="_blank"
				rel="noopener noreferrer"
				href={LINKEDIN}
				aria-label={t('aria_footer_social_media_linkedin')}
				title={t('aria_footer_social_media_linkedin')}
			>
				<LinkedinStyled aria-hidden="true" />
			</AnchorStyled>
		</Social>
	);
};

export default FooterSocial;
