import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import utilities
import { customMinResponsive, size } from 'components/styles';

// Import images
import { ReactComponent as Logo } from 'assets/icons/logo-secondary.svg';

export const FooterStyled = styled.footer`
	position: relative;
	background-color: ${({ theme: { body } }) => body.footer.bg};
	color: ${({ theme: { body } }) => body.footer.color};
`;

export const Wrapper = styled.div`
	height: 100%;
	padding: 4rem;
	margin: 0 auto;
	${flex({ jc: 'space-between', ai: 'flex-start', fd: 'column' })}

	${customMinResponsive(
		size.medium,
		css`
			padding: 4rem 8rem;
		`
	)}
`;

export const NavWrapper = styled.div`
	display: flex;

	${customMinResponsive(
		size.medium,
		css`
			align-items: center;
			gap: 6rem;
		`
	)}
`;

export const StyledLogo = styled(Logo)`
	width: 9.1rem;
	height: 8.5rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 16.9rem;
			height: 8.1rem;
		`
	)}
`;

export const FlexContainer = styled.div`
	width: 100%;
	${flex({ ai: 'flex-start' })}
	flex-direction: ${({ flexDirection }) =>
		flexDirection ? flexDirection : 'column'};

	> * {
		width: 100%;
		justify-content: center;
	}

	${customMinResponsive(
		size.medium,
		css`
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			> * {
				width: 33%;
			}
		`
	)}
`;

export const InnerContainer = styled.div`
	${flex({ jc: 'flex-start', ai: 'center', fd: 'column' })}

	gap: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			gap: 2.8rem;
		`
	)};

	${customMinResponsive(
		size.large,
		css`
			${flex({ jc: 'flex-start', ai: 'flex-end', fd: 'column' })}
			gap: 3rem;
		`
	)};
`;
