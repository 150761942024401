import * as types from 'store/actions/types';
import services from 'services/services';
import i18n from 'i18next';
import Cookie from 'js-cookie';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';

// Import variables
import { COOKIES_AFFILIATE_CLIENT } from 'helpers/variables';

// ******************** SUBMIT PRODUCT PURCHASE ********************
export const submitProductPurchase =
	(
		{
			pin = '',
			productId,
			priceId,
			promotion = null,
			paymentMethod,
			user_payment_data_id = null,
			gateway_type = null,
			isSubscriptionUpdate,
			need_invoice = false,
			client = null
		},
		callbackSuccess
	) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.PURCHASE_LOADING,
				payload: true
			});

			const { language } = i18n;
			const affiliateClientID = Cookie.get(COOKIES_AFFILIATE_CLIENT) || '';

			const query = `?language=${language}&aff_code=${affiliateClientID}`;

			let url = `/payments/${productId}/${priceId}/buy${query}`;

			const voucherCode = promotion?.voucherCode ?? '';

			const options = {
				data: { pin, code: voucherCode },
				need_invoice,
				client
			};

			if (user_payment_data_id) {
				options.user_payment_data_id = user_payment_data_id;
			}

			if (isSubscriptionUpdate) {
				options.update_subscription = true;
			}

			if (gateway_type) {
				options.data.gateway_type = gateway_type;
			}

			if (promotion?.id) {
				url += `&promotion_id=${promotion.id}`;
			}

			const { data } = await services.post(url, options);

			// Dispatch an action
			dispatch({
				type: types.PURCHASE_SUCCESS,
				payload: { data, paymentMethod }
			});
			callbackSuccess();
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);

				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
			}

			// Dispatch an error action
			dispatch({
				type: types.PURCHASE_ERROR,
				payload: error
			});
		}
	};

export const clearPurchase = (dispatch) =>
	dispatch({ type: types.PURCHASE_CLEAR });
