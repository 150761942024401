// Import helpers
import { APP_CORE_PATHS } from './app';

const { USER } = APP_CORE_PATHS;

export const USER_PROFILE_PATHS = {
	GENERAL: `/${USER}/profil`,
	LANGUAGE: `/${USER}/jezyk`,
	PARENTAL_CONTROL: `/${USER}/kontrola-rodzicielska`,
	PRODUCTS: `/${USER}/aktywne-zakupy`,
	PURCHASE_HISTORY: `/${USER}/historia-zakupow`,
	SUBSCRIPTIONS: `/${USER}/subskrypcje`,
	FAVOURITES: `/${USER}/ulubione`,
	REGULATION: `/${USER}/dokument/regulamin`,
	POLICY: `/${USER}/dokument/polityka-prywatnosci`,
	COOKIES_POLICY: `/${USER}/dokument/polityka-cookies`,
	DOCUMENT: `/${USER}/dokument/:alias`
};
