import styled from 'styled-components';

// Import icons
import { ReactComponent as GearsIcon } from 'assets/icons/ic_gears.svg';
import { ReactComponent as IconPortalSVG } from 'assets/icons/logo-primary.svg';

export const Container = styled.div`
	padding-top: 10vh;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContainerItem = styled.div`
	max-width: 790px;
	width: 100%;
	padding: 0 15px;
`;

export const PortalLogo = styled(IconPortalSVG)`
	margin: 0 auto;
	display: block;
	width: 146px;
	height: 94px;
`;

export const ErrorIcon = styled(GearsIcon)`
	display: block;
	margin: 0 auto;
	width: 150px;

	> g {
		path {
			fill: ${({ theme }) => theme.portalUnavailable.icon};
		}
	}
`;

export const ErrorText = styled.p`
	color: ${({ theme }) => theme.portalUnavailable.color};
	font-weight: ${({ theme }) => theme.font.normal};
	text-align: center;
	font-size: ${({ theme }) => theme.fontSize.portalUnavailable}rem;
	margin: 55px 0 45px 0;
	letter-spacing: 0.27px;
	line-height: 120%;
`;
