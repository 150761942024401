import services from 'services/services';

// Import api
import {
	GET_SUBSCRIBER_PROMOTIONS_API,
	GET_LAST_GATEWAY_API,
	GET_PRODUCT_PRICES_API
} from 'helpers/api';

// Import helpers
import { getProductDetailsApi } from 'helpers';

export const RESOURCES_NAMES = {
	PRODUCT_PRICES: 'productPrices',
	PRODUCT_DETAILS: 'productDetails',
	SUBSCRIBER_PROMOTIONS: 'subscriberPromotions',
	LAST_GATEWAY: 'lastGateway'
};

export const fetchProductPrices = ({ id }) => {
	const url = GET_PRODUCT_PRICES_API.replace('id', id);
	return services.get(url);
};

export const fetchProductDetails = (params) => {
	const url = getProductDetailsApi(params);
	return services.get(url);
};

export const fetchSubscriberPromotions = () => {
	return services.get(GET_SUBSCRIBER_PROMOTIONS_API);
};

export const fetchLastGateway = () => {
	return services.get(GET_LAST_GATEWAY_API);
};

export const prepareResourcesResponse = ({ response, resourcesFunctions }) => {
	return response.reduce((acc, next, index) => {
		const { data } = next;
		const { name } = resourcesFunctions[index];

		acc[name] = data;

		return acc;
	}, {});
};

export const filterPaymentMethods = ({ data, userPaymentMethods }) => {
	return data.filter(({ payment_method }) =>
		userPaymentMethods.includes(payment_method)
	);
};
