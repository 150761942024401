import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Import components
import Mobile from 'components/views/mobile';
import Routes from './Routes';
import { Static } from './helpers/components';

// Import logic
import useAppRoutesDesktop from './useAppRoutesDesktop';

// Import helpers
import { DOCUMENTS_PATHS } from '../paths';

const { DOCUMENT } = DOCUMENTS_PATHS;

const DesktopOnlyRoutes = () => {
	const { isMobile } = useAppRoutesDesktop();

	if (isMobile)
		return (
			<Switch>
				<Route path={DOCUMENT} component={Static} />
				<Route path="*" component={Mobile} />
			</Switch>
		);

	return <Routes />;
};

export default DesktopOnlyRoutes;
