import { css } from 'styled-components';

// import helpers
import { checkAppType } from 'helpers';

// Icons
export const iconClock = css`
	height: 1.4rem;
	width: 1.4rem;
	margin-right: 0.5rem;

	g,
	path {
		fill: ${({ theme }) => theme.grayMedium};
	}
`;

export const iconController = css`
	height: 2.8rem;
	width: 2.8rem;
	path {
		transition: all 0.3s ease-in-out;
		fill: ${({ theme }) => theme.player.button};
	}
`;

export const iconSearch = (height = 2.6, width = 2.6) => css`
	height: ${height}rem;
	width: ${width}rem;

	path {
		fill: ${({ theme }) => theme.search.closeIcon};
	}
`;

// Row
export const row = () => css`
	width: 117rem;
	height: 100%;
	margin: 0 auto;
	padding: 0 1.5rem;
`;

// Flex
export const flex = ({ jc = 'center', ai = 'center', fd = 'row' } = {}) => css`
	display: flex;
	justify-content: ${jc};
	align-items: ${ai};
	flex-direction: ${fd};
`;

export const flexColumn = () => css`
	display: flex;
	flex-direction: column;
`;

// Linear gradient
export const linearGradient = ({
	deg = 'to right',
	yper = '0',
	rper = '100%',
	mainTheme = 'primaryLight',
	secondTheme = 'primary'
} = {}) => css`
	background: linear-gradient(
		${deg},
		${({ theme }) => theme[mainTheme]} ${yper},
		${({ theme }) => theme[secondTheme]} ${rper}
	);
`;

export const linearGradientGray = ({
	deg = 'to right',
	yper = '0',
	rper = '100%'
} = {}) => css`
	background: linear-gradient(
		${deg},
		${({ theme }) => theme.veryLightGray} ${yper},
		${({ theme }) => theme.veryLightGray} ${rper}
	);
`;

// Scroll
export const scroll = ({ track, thumb, scrollbarWdth = '12' } = {}) =>
	css`
		&::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme[track]};
		}

		&::-webkit-scrollbar {
			width: ${scrollbarWdth}px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme[thumb]};
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px
				${({ theme }) => theme.general.shadowColor};
		}
	`;

// Ant Modal
export const antModal = () => css`
	&& {
		.ant-modal-body {
			button {
				background-color: ${({ theme }) => theme.globalButtons.primary.bgColor};
				border-color: ${({ theme }) => theme.globalButtons.primary.borderColor};
				color: ${({ theme }) => theme.globalButtons.primary.color};
				&:hover {
					background-color: ${({ theme }) =>
						theme.globalButtons.primary.hoverBgColor};
					border-color: ${({ theme }) =>
						theme.globalButtons.primary.hoverBorderColor};
					box-shadow: 0 0 4px
						${({ theme }) => theme.globalButtons.primary.hoverBorderColor};
				}
			}
		}

		.ant-modal-title {
			text-align: center;
			font-size: 2.4rem;
			color: ${({ theme }) => theme.white};
			font-weight: ${({ theme }) => theme.font.normal};
			font-family: 'Roboto', sans-serif;
			margin: 0 auto 2.4rem auto;
		}

		.ant-modal-footer {
			display: none;
		}

		.ant-modal-content,
		.ant-modal-header {
			background-color: ${({ theme }) => theme.modal.bg};
			padding: 0;
		}

		.ant-modal-content {
			border: solid 1px ${({ theme }) => theme.modal.transparentBorderColor};
			padding: 0 0 6rem 0;
		}

		.ant-modal-header {
			border-bottom: transparent;
			padding: 6rem 0 0 0;
		}

		.ant-modal-close {
			color: ${({ theme }) => theme.white};
		}
	}
`;

// Transitions
export const transitionMain = () => css`
	transition: all 0.3s ease-in-out 0s;
`;

// TV Smart
export const getNavLinkUnderline = () => {
	const { isTvSmart } = checkAppType();

	if (isTvSmart) {
		return css`
			background-image: linear-gradient(
				to right,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorStart} 0,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorMiddle} 50%,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorEnd} 100%
			);
		`;
	}

	return css`
		background-image: linear-gradient(
			to right,
			${({ theme }) => theme.activeGradientLine.colorStart} 0,
			${({ theme }) => theme.activeGradientLine.colorEnd} 100%
		);
	`;
};

// Section background
export const sectionBackground = (image, isSectionDetails = false) => {
	const backgroundAttachment = isSectionDetails ? 'fixed' : 'unset';

	return css`
		background: linear-gradient(
				${({ theme }) => theme.general.shadowColor},
				${({ theme }) => theme.general.shadowColor}
			),
			url(${image}) no-repeat center top/cover;
		background-attachment: ${backgroundAttachment};
	`;
};

export const itemFocus = ({ borderWidth = '3px' } = {}) => css`
	position: relative;
	outline: none;

	&:after {
		content: '';
		position: absolute;
		border: ${borderWidth} solid
			${({ theme }) => theme.slider.item.image.borderFocus};
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		box-shadow: 0 0 4px ${({ theme }) => theme.slider.item.image.shadowFocus};
	}
`;

export const textEllipsis = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const productTextColor = css`
	a {
		text-decoration: underline;
	}

	${({ theme, isLightBackground }) =>
		isLightBackground
			? css`
					color: ${theme.product.lightBackground.color} !important;

					a {
						color: ${theme.product.lightBackground.link};
						&:focus,
						&:hover {
							color: ${theme.product.lightBackground.linkHover};
						}
					}
			  `
			: css`
					color: ${theme.product.coverBackground.color} !important;

					a {
						color: ${theme.product.coverBackground.link};
						&:focus,
						&:hover {
							color: ${theme.product.coverBackground.linkHover};
						}
					}
			  `};
`;

export const productBackgroundColor = css`
	background-color: ${({ theme, isLightBackground }) =>
		isLightBackground
			? theme.product.lightBackground.background
			: theme.product.coverBackground.background};
`;

export const setIconColor = (color) => css`
	path {
		fill: ${color};
	}
`;
