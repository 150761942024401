import React from 'react';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as ParpLogo } from 'assets/icons/parp_logo.svg';

// Import styles
import { Text, Wrapper } from './styles';

const AdditionalInfo = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<ParpLogo />

			<Text>{t('common_footer_additional_info')}</Text>
		</Wrapper>
	);
};

export default AdditionalInfo;
