import React, { useReducer, useEffect, useMemo } from 'react';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import utilities
import { useAgreements } from 'components/utilities';

// Import reducer
import reducer from './store/reducer';

// Import actions
import { setSelectedPriceId, setTransactionType } from './store/actions';

// Import helpers
import { PAYMENT_AGREEMENTS, QUERY_PAYMENT_AGREEMENTS } from 'helpers';
import {
	INITIAL_STATE,
	DEFAULT_PAYMENT_METHOD,
	getTransactionType
} from './helpers';

// Import hooks
import { useResources } from './hooks';

// Create context
export const ProductPaymentContext = React.createContext();

const ProductPaymentProvider = ({ children }) => {
	const { state: locationState } = useLocation();

	// ---- state reducer ----
	const [state, stateDispatch] = useReducer(reducer, INITIAL_STATE);
	const invoiceFormRef = React.useRef(null);
	// ---- redux data ----

	const resources = useResources({ state, stateDispatch });

	// ---- variables ----
	const { paymentMethods, selectedPriceId } = state;

	const initialPriceId = locationState?.priceId;
	const isSubscriptionUpdate = locationState?.isSubscriptionUpdate ?? false;

	const agreementsStatus = useAgreements({
		queryKey: QUERY_PAYMENT_AGREEMENTS,
		agreementsToFetch: PAYMENT_AGREEMENTS
	});

	const method = useMemo(() => {
		const method = paymentMethods.find(({ id }) => id === selectedPriceId);
		return method ?? paymentMethods[0] ?? DEFAULT_PAYMENT_METHOD;
		// eslint-disable-next-line
	}, [selectedPriceId, paymentMethods.length]);

	// ---- effect ----
	useEffect(() => {
		// set promotions
		if (selectedPriceId && paymentMethods.length) {
			const type = getTransactionType(state);
			setTransactionType(type)(stateDispatch);
		}
		// eslint-disable-next-line
	}, [selectedPriceId, paymentMethods.length]);

	useEffect(() => {
		// set selected method id
		setSelectedPriceId(initialPriceId)(stateDispatch);
		// eslint-disable-next-line
	}, [initialPriceId]);

	return (
		<ProductPaymentContext.Provider
			value={{
				...state,
				...agreementsStatus,
				...resources,
				invoiceFormRef,
				dispatch: stateDispatch,
				isSubscriptionUpdate,
				method
			}}
		>
			{children}
		</ProductPaymentContext.Provider>
	);
};

ProductPaymentProvider.propTypes = {
	children: node
};

export default ProductPaymentProvider;
