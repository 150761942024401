import Store from 'store/store';
import {
	signOut,
	setPortalAvailability,
	setInternalServerError
} from 'store/actions';
import i18n from 'i18next';

// import helpers
import { checkAppType } from 'helpers';
import { getInstitutionFromStorage } from 'helpers/institutions';

// Import variables
import { SYSTEM_TYPES, LANGUAGES_TYPES } from 'helpers/variables';

const checkServerStatus = (error) => {
	const { url } = error.response.config;

	if (!url.includes('recordings')) {
		Store.dispatch(setInternalServerError(true));
	}
};

export const checkErrorStatus = (error) => {
	const {
		response: { status }
	} = error;
	if (status === 401) {
		const authenticate = false;
		// Dispatch an signOut action in auth folder
		Store.dispatch(signOut(authenticate));

		return Promise.reject(error);
	} else if (status === 502) {
		Store.dispatch(setPortalAvailability());
	} else if (status === 500) {
		checkServerStatus(error);
	} else {
		return Promise.reject(error);
	}
};

export const checkUrlPlatform = (url) => {
	const platform = `platform=BROWSER`;
	const regex = /\w*platform=BROWSER\w*/i.test(url);
	const systemName = getUrlSystemName();

	let requestUrl = url;

	if (!regex) {
		const type = !/\?/i.test(url) ? '?' : '&';
		requestUrl = `${url}${type}${platform}`;
	}

	return `${requestUrl}&system=${systemName}`;
};

export const getRating = () => {
	const { auth } = Store.getState();

	const isAuthDataAvailable = auth && auth.authenticate;

	if (isAuthDataAvailable) {
		// Use parental_control_data only after user login (rating === undefined)
		if (typeof auth.authenticate.rating === 'undefined') {
			return auth.authenticate.parental_control_rating
				? `&rating=${auth.authenticate.parental_control_rating}`
				: '';
		}
		// In other case scenarios use rating to determine query string params
		else {
			return auth.authenticate.rating || auth.authenticate.rating === 0
				? `&rating=${auth.authenticate.rating}`
				: '';
		}
	} else {
		return '';
	}
};

export const getInstitution = () => {
	const institution = getInstitutionFromStorage();

	const uuid = institution?.uuid;

	return uuid ? `&products_group_uuid=${uuid}` : '';
};

export const createAxiosInterceptors = (axios) => {
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return checkErrorStatus(error);
		}
	);
	return axios;
};

export const getUrlSystemName = () => {
	const { OTT, TVONLINE } = SYSTEM_TYPES;
	const { isTvSmart } = checkAppType();
	const system = isTvSmart ? OTT : TVONLINE;

	return system;
};

// convert errors from request response validators
export const convertErrors = (response) =>
	Object.keys(response).reduce((prev, next, index) => {
		prev[next] = Object.values(response)[index][0];
		return prev;
	}, {});

// set custom text to errors
export const addCustomTranslationToErrors = (errors, translationText) => {
	Object.keys(errors).forEach((key) => (errors[key] = translationText));
	return errors;
};

export const getLanguage = () => {
	const { UK, UA } = LANGUAGES_TYPES;
	const { language } = i18n;

	const lang = language === UK ? UA : language;

	return `&language=${lang}`;
};

export const prepareFetchApiUrl = (url) => {
	const baseUrl = checkUrlPlatform(url);
	const rating = getRating();
	const institution = getInstitution();
	const language = getLanguage();

	return `${baseUrl}${rating}${institution}${language}`;
};
