import { notification } from 'antd';

export const openNotification = ({
	type,
	title,
	description,
	duration = 7
}) => {
	notification[type]({
		message: title,
		description,
		duration
	});
};
