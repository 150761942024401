import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import styles
import { size, customMinResponsive } from 'components/styles';

const PaymentField = ({
	type = 'text',
	label,
	passwordClassName,
	field,
	form: { touched, errors },
	placeholder,
	disabled = false,
	isFocus = false,
	maxLength = 4
}) => {
	const inputRef = React.createRef(null);
	const { name } = field;
	const errorText = errors[name] || '';
	const isErrorVisible = touched[name] && errors[name];

	React.useEffect(() => {
		// Set focus
		isFocus && inputRef.current.focus();
		// eslint-disable-next-line
	}, [isFocus]);

	return (
		<FormFieldStyled className={passwordClassName}>
			{label && <Label>{label}</Label>}
			<Input
				ref={inputRef}
				className={`${passwordClassName && passwordClassName} ${
					touched[name] && errors[name] ? 'errors' : ''
				}`}
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				maxLength={maxLength}
				{...field}
			/>
			<FormError
				isErrorVisible={isErrorVisible}
				role="alert"
				aria-atomic="true"
				id={`${name}-alert`}
			>
				{errorText}
			</FormError>
		</FormFieldStyled>
	);
};

const FormError = styled.span`
	display: ${({ isErrorVisible }) => (!isErrorVisible ? 'none' : 'block')};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.6)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.red};
	top: 2.5rem;
	position: absolute;
	${customMinResponsive(
		size.medium,
		css`
			top: 4.8rem;
			font-size: ${({ theme }) => theme.fontSize.set('xs', -0.5)}rem;
		`
	)}
`;

const FormFieldStyled = styled.div`
	margin-top: 2.8rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.blik {
		position: relative;
		${customMinResponsive(
			size.medium,
			css`
				margin-top: 0;
				margin-bottom: 3rem;
			`
		)}
	}
`;

const Label = styled.label`
	color: ${({ theme }) => theme.payment.paymentOptions.paymentField.labelColor};
	font-size: 1.5rem;
	margin-bottom: 2.4rem;
	letter-spacing: 0.3px;
`;

const Input = styled.input`
	width: 16rem;
	border: 1px solid transparent;
	border-bottom: 1px solid
		${({ theme }) => theme.payment.paymentOptions.paymentField.borderColor};
	text-align: center;
	background: transparent;
	color: ${({ theme }) => theme.payment.paymentOptions.paymentField.color};
	font-size: 7rem;
	padding-bottom: 2.5rem;
	height: 3.5rem;
	letter-spacing: 3.5px;

	&:focus {
		outline: none;
	}

	&.errors {
		border-bottom: 1px solid ${({ theme }) => theme.error.border};
	}

	&.blik {
		font-size: 1rem;
		padding: 0;
		width: 14rem;
		height: 2.5rem;
		border: 1px solid
			${({ theme }) => theme.payment.paymentOptions.paymentField.borderColor};

		${customMinResponsive(
			size.medium,
			css`
				font-size: 1.2rem;
				width: 17rem;
				height: 4.5rem;
			`
		)}
	}

	&.mask {
		font-family: 'text-security-disc';
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) =>
			theme.payment.paymentOptions.paymentField.placeholderColor};
	}
`;

PaymentField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	passwordClassName: PropTypes.string,
	isFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	maxLength: PropTypes.number
};

export default PaymentField;
