import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { getPriceIdByMethod } from 'helpers';

// Import variables
import { PAYMENT_METHODS } from 'helpers/prices';

// ******************** FETCH PAYEMENT TYPES ********************
export const fetchPaymentTypes = (productId, period) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_LOADING,
			payload: true
		});

		// Get fetch payment type
		const url = `/products/${productId}/prices/${period}`;
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_ERROR,
			payload: error
		});
	}
};

// ******************** FETCH PAYEMENT INIT ********************
export const fetchInitPayment = ({
	productId,
	priceId,
	promotion,
	isSubscriptionUpdate = false
}) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PAYMENT_INIT_LOADING
		});

		let initUrl = `/payments/${productId}/${priceId}/init`;

		const body = { update_subscription: false };

		if (promotion && promotion.id) {
			initUrl += `?context=out&promotion_id=${promotion.id}`;
		}

		if (isSubscriptionUpdate) {
			body.update_subscription = true;
		}

		const { data } = await services.post(initUrl, body);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PAYMENT_INIT_SUCCESS,
			payload: data
		});

		// return request data for purchase action
		return data;
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.FETCH_PAYMENT_INIT_ERROR,
			payload: error
		});
	}
};

// ******************** FETCH PAYEMENT AGREEMENTS ********************
export const fetchPaymentAgreements = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_LOADING,
			payload: true
		});

		// Get fetch payment type
		const url = `/payments/agreements`;
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_ERROR,
			payload: error
		});
	}
};

// ******************** CHECK INVOICE CREDITS ********************
export const checkInvoiceCredits = ({
	productId,
	paymentOptions,
	promotion
}) => async (dispatch) => {
	try {
		const priceId = getPriceIdByMethod(PAYMENT_METHODS.INVOICE, paymentOptions);

		let url = `/payments/${productId}/${priceId}/hasEnoughCredits`;

		if (promotion && promotion.id) {
			url += `?context=out&promotion_id=${promotion.id}`;
		}

		const { data } = await services.post(url);

		dispatch({
			type: types.CHECK_INVOICE_CREDITS,
			payload: data.credits_enough
		});
	} catch (error) {
		dispatch({
			type: types.CHECK_INVOICE_CREDITS,
			payload: false
		});
	}
};

export const clearPayment = (dispatch) =>
	dispatch({ type: types.PAYMENT_CLEAR });

// set memorized agreements
export const setMemorizedAgreements = (agreements, dispatch) => {
	dispatch({ type: types.SET_MEMORIZED_AGREEMENTS, payload: agreements });
};

// clear memorized agreements
export const clearMemorizedAgreements = (dispatch) => {
	dispatch({ type: types.CLEAR_MEMORIZED_AGREEMENTS });
};
