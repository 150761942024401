import * as types from 'store/actions/types';
import services from 'services/services';
import Cookie from 'js-cookie';
import i18n from 'i18next';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import {
	getUrlSystemName,
	convertErrors,
	addCustomTranslationToErrors
} from 'services/helpers';
import { getAffiliateClientQuery } from 'helpers/affiliate_client';

// Import helpers
import { FRESH_MAIL_API } from 'helpers/api';
import { checkForInvalidDataError } from 'store/helpers/error_status';

// Import variables
import { COOKIE_NEWSLETTER } from 'helpers/variables';
import { NEWS_LETTER_ERROR_CODES } from 'helpers/newsletter';

// ******************** REGISTER USER ********************
const subscribeNewsletter = (user) => {
	// Freshmail newsletter
	const newsletterAgreement = Cookie.get(COOKIE_NEWSLETTER);
	const isNewsletter = !!parseInt(newsletterAgreement, 10);

	if (isNewsletter) {
		const listId = process.env.REACT_APP_FRESH_MAIL_HASH_LIST;
		const options = { email: user.email, list: listId };
		services.post(FRESH_MAIL_API, options);
	}
};

export const registerUser =
	(userData, agreementData, setErrors) => async (dispatch) => {
		try {
			// Subscribe to freshmail's newsletter
			subscribeNewsletter(userData);
			dispatch({
				type: types.REGISTER_USER_LOADING
			});
			// Add system name to request (tvonline || ott)
			const system = getUrlSystemName();

			// Get AffiliateClient ID query
			const query = getAffiliateClientQuery();

			// Check if obligatory agreements are accepted
			// (they always are since it's obligatory condition to dispatch action in RegisterForm)
			const updatedData = {
				...userData,
				system,
				agreements_accepted: agreementData
			};

			// Registration
			const url = '/register';
			await services.post(`${url}${query}`, updatedData);

			dispatch({
				type: types.REGISTER_USER_SUCCESS
			});
		} catch (error) {
			const response = error?.response;
			const responseData = response?.data;

			const dispatchError = () =>
				dispatch({
					type: types.REGISTER_USER_ERROR,
					payload: error.response
				});

			if (response) {
				const { codeErrorMessage } = getErrorCodeResults(error);
				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
			}

			// One exeption becouse error must be visible but there is
			// no translation to this message
			checkForInvalidDataError(error);

			if (responseData?.validator?.errors && response.status === 422) {
				// convert errors from request to format that is accepted by Formik
				const errors = convertErrors(error.response.data.validator.errors);

				// In this case translate all data from request validator to custom text
				addCustomTranslationToErrors(errors, 'Nieprawidłowy format danych');

				//set errors in proper fields in Formik
				setErrors(errors);
			}

			if (responseData?.errorCodeNumber) {
				const errorCodeNumber = responseData.errorCodeNumber;
				const errorNewsLettterCode = Object.keys(NEWS_LETTER_ERROR_CODES).find(
					(code) => parseInt(code) === errorCodeNumber
				);

				if (errorNewsLettterCode) {
					const transKey = NEWS_LETTER_ERROR_CODES[errorNewsLettterCode];
					const message = i18n.t(transKey);
					error.response.data.message = message;
					setErrors({ email: message });
					dispatchError();
				} else {
					dispatchError();
				}
			} else {
				dispatchError();
			}
		}
	};

// ******************** FETCH REGISTER AGREEMENTS ********************
export const fetchRegisterAgreements = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_LOADING
		});

		const url = 'register/agreements';
		const { data } = await services.get(url);

		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_ERROR
		});
	}
};

export const clearRegisterAgreements = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_REGISTER_AGREEMENTS
	});
};

// ******************* Clear register data *******************
export const clearRegisterData = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_REGISTER_DATA
	});
};
