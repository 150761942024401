import firebase from 'firebase/app';
import 'firebase/messaging';
// import i18n from 'i18next';
// import services from 'services/services';

// Import components
import { notification } from 'antd';

// Import helpers
// import { checkErrorStatus } from 'store/helpers/error_status';

const openNotification = ({ title, body }) => {
	notification.open({
		message: title,
		description: body,
		duration: 30
	});
};

// const sendTokenToServer = async (currentToken) => {
// 	try {
// 		const payload = {
// 			token: currentToken
// 		};

// 		await services.post('/subscriber/notifications/register', payload);
// 	} catch (error) {
// 		checkErrorStatus(error) &&
// 			notification.open({
// 				message: i18n.t('common_reminders_error_title'),
// 				description: i18n.t('common_reminders_error_description_notification'),
// 				duration: 8
// 			});
// 	}
// };

export function initializePush() {
	const { isSupported } = firebase.messaging;

	if (isSupported()) {
		const messaging = firebase.messaging();
		messaging
			.requestPermission()
			.then(() => {
				return messaging.getToken();
			})
			.then((token) => {
				//send new found FCM token to the server
				// sendTokenToServer(token);
			})
			.catch((err) => {
				console.log('An error occurred while retrieving token. ', err);
			});

		messaging.onMessage((payload) => {
			openNotification(payload.notification);
		});
	}
}
