import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Import actions
import { addReminder } from 'store/actions';

// import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import { getProductType } from 'helpers/products';

// Import styles
import { OptionItem } from './styles';

const { PROGRAM, LIVE_EVENT } = PRODUCTS_TYPES;

const OptionsItem = ({ minutes, timeDifference }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { pathname } = useLocation();

	// check path to switch between live and program
	const productType =
		getProductType(pathname) === 'live' ? LIVE_EVENT : PROGRAM;

	if (timeDifference < minutes) return null;

	const handleOffsetSelect = () => {
		const data = { productUuid: id, productType, offset: minutes };
		addReminder(data)(dispatch);
	};

	return (
		<OptionItem onSelect={handleOffsetSelect}>
			{t(`common_reminders_list_${minutes}_before`)}
		</OptionItem>
	);
};

OptionsItem.propTypes = {
	minutes: PropTypes.number.isRequired,
	timeDifference: PropTypes.number.isRequired
};

export default OptionsItem;
