import _ from 'lodash';

// Import helpers
import { getPeriodWithUnit } from 'helpers';

// VARIABLES
export const OTHER_PAYMENT_METHODS = 'other_payment_methods';

export const ONE_TIME = 'one_time';

export const RECURRING = 'recurring';

export const GATEWAY_TYPE = 'gateway_type';

export const RECURRING_GATEWAY_TYPE = 'recurring_gateway_type';

export const DEFAULT_PRICE_VALUES = {
	period: null,
	price: null,
	promotions: null
};

export const TRANSACTION_TYPES = {
	PURCHASE: 'purchase',
	RENT: 'rent',
	SUBSCRIPTION: 'subscription'
};

export const SUBSCRIPTION_STATUS_TYPES = {
	ACTIVE: 'active',
	CANCELED: 'canceled',
	PENDING: 'pending'
};

export const GATEWAY_TYPES = {
	CARD: 'card',
	CARD_RECURRING: 'card_recurring',
	BLIK: 'blik',
	BLIK_RECURRING: 'blik_recurring',
	GOOGLE_PAY: 'google_pay',
	GOOGLE_PAY_RECURRING: 'google_pay_recurring',
	APPLE_PAY: 'apple_pay',
	APPLE_PAYRECURRING: 'apple_pay_recurring',
	OTHER_BLUEMEDIA: 'other_bluemedia',
	UNKNOWN: 'unknown'
};

export const PAYMENT_METHODS = {
	INVOICE: 'invoice',
	CARD: 'card',
	EXTERNAL: 'external',
	BLUEMEDIA_ONE_TIME: 'bluemedia',
	BLUEMEDIA_SUBSCRIPTION: 'subscription_bluemedia'
};

export const getLowestPrices = (data) => {
	const prices = data.map((price) => {
		const values = Object.values(price);
		const entries = Object.entries(price);

		// get price id
		const {
			id: priceId,
			limit: priceLimit,
			period,
			period_unit: periodUnit
		} = price.regular;

		const periodWithUnit = getPeriodWithUnit({ period, periodUnit });

		// sort regular and promotion by price and get the lowest item
		const [lowest] = _.sortBy(values, 'price');

		// map entries to get arrays of two values
		// and use fromEntries to convert array to object
		const prices = Object.fromEntries(
			entries.map(([key, value]) => [key, value.price])
		);

		return {
			...prices,
			lowest: lowest.price,
			priceId,
			priceLimit,
			periodWithUnit
		};
	});

	return _.sortBy(prices, 'lowest')[0];
};

export const isNumber = (value) => typeof value === 'number';
