import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { PRODUCTS_TYPES } from 'helpers/variables';

const { PROGRAM, LIVE_EVENT } = PRODUCTS_TYPES;

// FETCH REMINDERS
export function fetchReminders() {
	return async (dispatch) => {
		try {
			dispatch({
				type: types.FETCH_REMINDERS_LOADING,
				payload: true
			});

			// Temporary solution
			const programUrl = `/subscriber/notifications/reminders?type=${PROGRAM}`;
			const liveUrl = `/subscriber/notifications/reminders?type=${LIVE_EVENT}`;

			const programResponse = await services.get(programUrl);
			const liveResponse = await services.get(liveUrl);

			/* it has to wait because of add and delete functions
			which use this one to keep state up-to-date */
			await dispatch({
				type: types.FETCH_REMINDERS_SUCCESS,
				payload: [...programResponse.data, ...liveResponse.data]
			});
		} catch {
			dispatch({
				type: types.FETCH_REMINDERS_ERROR,
				payload: true
			});
		}
	};
}

/**
 * adds some reminder and updates reminders state
 * @param {number} programId - id of program that reminder should be set on
 * @param {number} timeBefore - how much minutes before program reminder should
 * start on
 * @returns {function}
 */
export function addReminder({ productUuid, productType, offset }) {
	return async (dispatch) => {
		try {
			dispatch({
				type: types.ADD_REMINDER_LOADING,
				payload: true
			});

			const url = '/subscriber/notifications/reminders/create';

			const payload = {
				product_uuid: productUuid,
				offset_in_minutes: offset,
				type: productType
			};

			await services.post(url, payload);

			// sets reminders state up-to-date
			await fetchReminders()(dispatch);
		} catch (error) {
			dispatch({
				type: types.ADD_REMINDER_ERROR,
				payload: true
			});

			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('common_reminders_error_title'),
					description: i18next.t('common_reminders_error_description')
				});
		}
	};
}

export function deleteReminder(id) {
	return async (dispatch) => {
		try {
			dispatch({
				type: types.DELETE_REMINDER_LOADING,
				payload: id
			});

			const url = `/subscriber/notifications/reminders/${id}`;

			await services.delete(url);

			// sets reminders state up-to-date
			await fetchReminders()(dispatch);
		} catch (error) {
			dispatch({
				type: types.DELETE_REMINDER_ERROR,
				payload: true
			});

			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('common_reminders_error_title'),
					description: i18next.t('common_reminders_error_description')
				});
		}
	};
}
