// API
export const GET_LAST_GATEWAY_API = '/payments/getLastGatewayType';
export const GET_PAYMENT_AGREEMENTS_API = '/payments/agreements';
export const GET_VOUCHER_PROMOTION_API = '/payments/id/code/promotion/prices';
export const GET_PRODUCT_PAYMENT_STATUS_API = '/payments/payment/last/:id';

export const POST_PAYMENT_INIT_API = '/payments/productId/priceId/init';
export const POST_PAYMENT_BUY_API = '/payments/productId/priceId/buy';

// subscriptions
export const POST_SUBSCRIPTION_DEACTIVATE_API =
	'/payments/subscriptions/deactivate';

// Queries
export const QUERY_PAYMENT_RESOURCES = 'QUERY_PAYMENT_RESOURCES';
export const QUERY_VOUCHER_PROMOTION = 'QUERY_VOUCHER_PROMOTION';
export const QUERY_PAYMENT_INIT_ = 'QUERY_PAYMENT_INIT';
export const QUERY_PAYMENT_BUY = 'QUERY_PAYMENT_BUY';
export const QUERY_PRODUCT_PAYMENT_STATUS = 'QUERY_PRODUCT_PAYMENT_STATUS';
