import React from 'react';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as GooglePlay } from 'assets/icons/ic_google_play.svg';
import { ReactComponent as AppStore } from 'assets/icons/ic_app_store.svg';

// Import helpers
import { EXTERNAL_LINKS, WATERMARK_URL } from 'helpers/variables';

// Import styles
import {
	Wrapper,
	Top,
	LogoImage,
	Content,
	HeadingSecondStyled,
	Paragraph,
	AnchorStyled,
	Stores
} from './styles';

const { GOOGLE_PLAY, APP_STORE } = EXTERNAL_LINKS;

const Mobile = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Top>
				<LogoImage src={WATERMARK_URL} alt="Logo" />
			</Top>
			<Content>
				<HeadingSecondStyled>
					{t('mobile_view_info_heading')}
				</HeadingSecondStyled>
				
				<Paragraph>{t('mobile_view_info_description')}</Paragraph>

				<Stores>
					<AnchorStyled
						target="_blank"
						rel="noopener noreferrer"
						href={GOOGLE_PLAY}
						aria-label={t('aria_label_google_play')}
						title={t('aria_label_google_play')}
					>
						<GooglePlay aria-hidden="true" />
					</AnchorStyled>

					<AnchorStyled
						target="_blank"
						rel="noopener noreferrer"
						href={APP_STORE}
						aria-label={t('aria_label_app_store')}
						title={t('aria_label_app_store')}
					>
						<AppStore aria-hidden="true" />
					</AnchorStyled>
				</Stores>
			</Content>
		</Wrapper>
	);
};

export default Mobile;
