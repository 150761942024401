import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, flex, size } from 'components/styles';

export const Wrapper = styled.div`
	${flex({ fd: 'column', ai: 'center' })}
	row-gap: 1.6rem;

	svg {
		width: 10rem;
	}

	${customMinResponsive(
		size.medium,
		css`
			${flex({ fd: 'column', ai: 'flex-end' })}
			margin-left: auto;
		`
	)}
`;

export const Text = styled.p`
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.6)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 1.3rem;
	color: ${({ theme }) => theme.body.footer.color};
	text-align: right;
	max-width: 32rem;
`;
