import React from 'react';
import { groupBy } from 'lodash';
import Cookie from 'js-cookie';
import { differenceInSeconds } from 'date-fns';

// Import helpers
import { getUserAgentInfo } from 'helpers';
import { getManifestUri } from '../helpers';

// Import variables
import {
	TRACKING_INTERVAL_TIME,
	LOCALSTORAGE_TRACKING
} from 'helpers/variables';

//Import colors
import { colors } from 'components/theme/colors';

// ******************** SHAKA PLAYER COMMON HELPERS ********************

// default tv UI config options
const getUIConfiguration = (isVodPlayer, isIphone) => {
	return {
		controlPanelElements: [],
		overflowMenuButtons: [],
		addBigPlayButton: isIphone,
		doubleClickForFullscreen: isVodPlayer,
		addSeekBar: false,
		enableFullscreenOnRotation: false,
		seekBarColors: {
			base: colors.darkGray,
			played: colors.semiDarkRed,
			buffered: colors.darkGray
		},
		fadeDelay: 3
	};
};

// player configuration
const getPlayerConfiguration = (drm, certificate) => {
	return {
		preferredAudioLanguage: 'pl',
		preferredTextLanguage: 'pl',
		streaming: {
			alwaysStreamText: true,
			bufferBehind: 10,
			bufferingGoal: 10,
			rebufferingGoal: 5
		},
		drm: {
			servers: {
				'com.widevine.alpha': drm.WIDEVINE,
				// 'com.microsoft.playready': drm.PLAYREADY,
				'com.apple.fps.1_0': drm.FAIRPLAY.src
			},
			advanced: {
				'com.apple.fps.1_0': {
					serverCertificate: new Uint8Array(certificate)
				},
				'com.widevine.alpha': {
					videoRobustness: 'SW_SECURE_CRYPTO',
					audioRobustness: 'SW_SECURE_CRYPTO'
				}
			}
		},
		abr: {
			enabled: true
		}
	};
};

// fetch data required to configure shaka player
export const fetchPlaylistData = async (playlist) => {
	try {
		const playlistData = await fetch(playlist).then((res) => res.json());
		const certificate = await fetch(playlistData.drm.FAIRPLAY.cert).then(
			(res) => res.arrayBuffer()
		);

		return { playlistData, certificate };
	} catch (error) {
		// do nothing, this error is empty object
	}
};

function addShakaPlayerListeners() {
	const { type, isCatchup } = this.props;
	const isVodPlayer = type === 'player_vod';

	// video events
	this.video.addEventListener('loadeddata', this.onReady);
	this.video.addEventListener('pause', this.onPause);
	this.video.addEventListener('play', this.onPlay);

	if (isVodPlayer || isCatchup) {
		this.video.addEventListener('ended', this.onEnded);
	} else if (isVodPlayer) {
		this.video.addEventListener('timeupdate', this.onTimeUpdate);
	}

	// player events
	this.player.addEventListener('error', this.onErrorEvent);
	this.player.addEventListener('buffering', this.onBufferStateChange);
	this.player.addEventListener('adaptation', this.onAutoQualityChange);
	// document events
	document.addEventListener('fullscreenchange', this.onFullScreenChange);
}

export function removeShakaPlayerListeners() {
	const { type, isCatchup } = this.props;
	const isVodPlayer = type === 'player_vod';

	// video events
	this.video.removeEventListener('loadeddata', this.onReady);
	this.video.removeEventListener('pause', this.onPause);
	this.video.removeEventListener('play', this.onPlay);

	if (isVodPlayer || isCatchup) {
		this.video.removeEventListener('ended', this.onEnded);
	} else if (isVodPlayer) {
		this.video.removeEventListener('timeupdate', this.onTimeUpdate);
	}

	// player events
	this.player.removeEventListener('error', this.onErrorEvent);
	this.player.removeEventListener('buffering', this.onBufferStateChange);
	this.player.removeEventListener('adaptation', this.onAutoQualityChange);
	// document events
	document.removeEventListener('fullscreenchange', this.onFullScreenChange);
}

export function playerInitialization(params) {
	const [shaka, playlistData] = params;

	const {
		playlistData: {
			drm,
			sources: { HLS, DASH },
			subtitles: movieSubtitles,
			use_watermark: useWatermark
		},
		certificate
	} = playlistData;

	const {
		configuration: { seekAvailable },
		type,
		isSafari,
		isIphone
	} = this.props;

	const isVodPlayer = type === 'player_vod';

	if (isVodPlayer) {
		this.setState({ movieSubtitles });
	} else {
		this.setState({ seekAvailable });
	}

	// Link to manifest
	const manifestUri = getManifestUri({ isSafari, hls: HLS, dash: DASH });

	//Initialize shaka player
	this.player = new shaka.Player(this.video);

	// Setting up shaka player UI
	const ui = new shaka.ui.Overlay(this.player, this.videoContainer, this.video);

	// configure default UI
	const uiConfig = getUIConfiguration(isVodPlayer, isIphone);
	ui.configure(uiConfig);
	ui.getControls();

	// player configure
	const playerConfig = getPlayerConfiguration(drm, certificate);
	this.player.configure(playerConfig);

	const getPlayerResources = isVodPlayer
		? getVodPlayerResources
		: getTvPlayerResources;

	// set initial volume for tv player
	!isVodPlayer && this.setInitialVolume();

	// Try to asynchronous manifest load
	this.player
		.load(manifestUri)
		.then(() => {
			this.streamStartDate = new Date();
			getPlayerResources.call(this);
			playerTrackingInit.call(this, { manifestUri: manifestUri });
			this.trackingInterval = setInterval(
				() => sendPlayerEvents.call(this),
				TRACKING_INTERVAL_TIME
			);
		})
		.catch(this.onError);

	// Set buffering
	this._isMounted && this.setState({ buffering: true, useWatermark });
	// create event listeners
	addShakaPlayerListeners.call(this);
}

export function setSupportError() {
	const error = { code: 'BROWSER_NOT_SUPPORTED' };
	this.onError(error);
	this._isMounted && this.setState({ buffering: false, isReady: false });
}

// player tracking initialization
function playerTrackingInit({ manifestUri }) {
	const { duration } = this.state;
	const {
		isSafari,
		configuration: {
			tracking: {
				collector,
				sessionId,
				customerId,
				subscriberId,
				productId,
				customData: { productType, materialId, login, main, accountId }
			}
		},
		type: playerType,
		isCatchup
	} = this.props;

	const {
		browser: { name: browserName, version: browserVersion },
		os: { name: osName, version: osVersion }
	} = getUserAgentInfo();
	const userId = Cookie.get('uid');

	const isVodPlayer = playerType === 'player_vod';
	const videoDuration = isVodPlayer || isCatchup ? Math.ceil(duration) : -1;

	const urlData = {
		basicUrl: `https:${collector}init.gif?`,
		deviceId: `deviceId=${userId}&`,
		version: `version=V1&`,
		sessionId: `sessionId=${sessionId}&`,
		customerId: `customerId=${customerId}&`,
		subscriberId: `subscriberId=${subscriberId}&`,
		terminal: `terminal=PC&`,
		mimeType: `mimeType=${isSafari ? 'HLS' : 'DASH'}&`,
		productId: `productId=${productId}&`,
		manifestUrl: `url=${encodeURIComponent(manifestUri)}&`,
		duration: `duration=${videoDuration}&`,
		agent: `agent=${browserName}&`,
		agentVersion: `agentVersion=${browserVersion}&`,
		os: `os=${encodeURIComponent(osName)}&`,
		osVersion: `osVersion=${osVersion}&`,
		maker: `maker=${browserName}&`,
		rendererType: `rendererType=HTML5&`,
		playerVersion: `playerVersion=ShakaPlayer_3.0.1&`,
		referrer: `referrer=${encodeURIComponent(window.location.href)}&`,
		platform: `platform=BROWSER&`,
		productType: `productType=${productType}&`,
		materialId: `materialId=${materialId}&`,
		login: `login=${login}&`,
		main: `main=${main}&`,
		accountId: `accountId=${accountId}`
	};

	const imageSrc = getTrackingUrl({ urlData });

	// send tracking init request
	const image = new Image();
	image.src = imageSrc;
}

// send events data for tracking
export function sendPlayerEvents() {
	const timeDiff = differenceInSeconds(new Date(), this.streamStartDate);
	const sessionDuration = this.streamStartDate ? timeDiff : 0;

	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	const {
		configuration: {
			tracking: { collector, sessionId, customerId }
		}
	} = this.props;

	const urlData = {
		basicUrl: `https:${collector}events.gif?`,
		sessionId: `sessionId=${sessionId}&`,
		customerId: `customerId=${customerId}&`,
		sessionDuration: `sessionDuration=${sessionDuration}&`,
		events: 'events='
	};

	let imageSrc = getTrackingUrl({ urlData });

	const isTrackingDataExist = trackingStorage?.events;

	const getTrackingEvents = ({ type, sessionOffset, trackOffset, bitrate }) => {
		return `${type},${sessionOffset},${trackOffset},${bitrate}`;
	};
	const events = isTrackingDataExist
		? trackingStorage.events.map(getTrackingEvents)
		: [];

	const eventsString = events.join(';');
	imageSrc += eventsString;

	const image = new Image();
	// clear tracking storage on send success
	image.onload = () => localStorage.removeItem(LOCALSTORAGE_TRACKING);
	// sava tracking data on send error
	image.onerror = () => {
		localStorage.setItem(
			LOCALSTORAGE_TRACKING,
			JSON.stringify({
				...trackingStorage,
				url: imageSrc
			})
		);
	};
	// send tracking events request
	image.src = imageSrc;
}

// get tracking url
const getTrackingUrl = ({ urlData }) => Object.values(urlData).join('');

// send previous session tracking data
export const sendPreviousSessionTracking = () => {
	const isOnline = navigator.onLine;
	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	if (trackingStorage?.url && isOnline) {
		const image = new Image();
		image.src = trackingStorage.url;
		localStorage.removeItem(LOCALSTORAGE_TRACKING);
	}
};

// set tracking event data to state
export function setTrackingEvent(eventName) {
	const trackingStorage = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_TRACKING)
	);

	// create event object
	const event = {
		name: eventName,
		bitrate: getCurrentBitrate.call(this),
		sessionOffset: getTimeSinceStreamStart.call(this),
		trackOffset: Math.ceil(this.video.currentTime),
		type: TRACKING_EVENT_INDEX[eventName]
	};

	const events = trackingStorage?.events
		? [...trackingStorage.events, event]
		: [event];

	localStorage.setItem(
		LOCALSTORAGE_TRACKING,
		JSON.stringify({
			...trackingStorage,
			events
		})
	);
}

// get difference between stream start time and current time in seconds
function getTimeSinceStreamStart() {
	const timeDiff = differenceInSeconds(new Date(), this.streamStartDate);
	const sessionDuration = this.streamStartDate ? timeDiff : 0;
	return sessionDuration;
}

// get current stream bitrate
function getCurrentBitrate() {
	const tracks = this.player.getVariantTracks();
	const currentTrack = tracks.find(({ active }) => active);
	const currentBitrate = currentTrack ? currentTrack.videoBandwidth / 1000 : 0;
	return currentBitrate;
}

// tracking events index
export const TRACKING_EVENT_INDEX = {
	PLAYING: 0,
	BUFFERING: 1,
	PAUSED: 2,
	SEEKING: 3,
	TRACK_CHANGED: 4,
	COMPLETE: 5,
	STOPPED: 6,
	CLOSED: 7,
	ERROR: 8
};

const getVideoVariants = ({ player }) => {
	const languages = player.getAudioLanguages();

	const videoVariants = player
		.getVariantTracks()
		.sort((a, b) => b.height - a.height);
	const profiles = groupBy(videoVariants, 'language');

	const switchHistory = player.getStats().switchHistory;
	const initVariant = switchHistory.find(({ type }) => type === 'variant');
	const selectedLanguage = videoVariants.find(
		({ id }) => id === initVariant.id
	).language;

	return { languages, profiles, selectedLanguage };
};

// ******************** SHAKA PLAYER VOD HELPERS ********************

// get subtitles, audio and video variants
function getVodPlayerResources() {
	const { isSafari } = this.props;

	if (!isSafari) {
		const { movieSubtitles } = this.state;

		movieSubtitles.forEach(({ language: lang, url }) => {
			this.player.addTextTrack(url, lang, 'subtitle', 'text/vtt', '', lang);
		});

		const { languages, profiles, selectedLanguage } = getVideoVariants({
			player: this.player
		});

		this.setState({ languages, profiles, selectedLanguage });
	}

	const subtitles = this.player.getTextTracks();
	this.setState({ subtitles, duration: this.video.duration });
}

export function renderSubtitlesForSafari() {
	const { movieSubtitles } = this.state;
	return movieSubtitles.map(({ id, language, url }) => (
		<track
			key={id}
			label="Subtitles"
			kind="subtitles"
			srcLang={language}
			src={url}
			default=""
		/>
	));
}

// ******************** SHAKA PLAYER TV HELPERS ********************

function getTvPlayerResources() {
	const { isCatchup } = this.props;

	const { languages, profiles, selectedLanguage } = getVideoVariants({
		player: this.player
	});

	this.setState({
		languages,
		profiles,
		selectedLanguage,
		duration: this.video.duration
	});

	if (isCatchup) {
		this.video.currentTime = 2;
	}
}
