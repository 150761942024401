import styled from 'styled-components';

// Import components
import { Heading } from 'components/elements';

// Import styles
import { flex } from 'components/styles';

export const Wrapper = styled.div`
	flex: 1;
	height: 100%;
	width: 100%;
	background-size: cover;
	padding-bottom: 6rem;
`;

export const Top = styled.div`
	margin-top: 4.5rem;
	padding: 1em 0 3em;
	text-align: center;
`;

export const LogoImage = styled.img`
	width: 75%;
	max-width: 18.2rem;
	height: auto;
`;

export const Content = styled.div`
	text-align: center;
`;

export const HeadingSecondStyled = styled(Heading.Second)`
	font-size: 2.2rem;
	line-height: 2.9rem !important;
	padding: 0 3rem;
	margin-top: 8.5rem;

	span {
		display: block;
		text-transform: uppercase;
	}
`;

export const Paragraph = styled.p`
	color: ${({ theme }) => theme.veryLightGray};
	font-size: 1.4rem;
	margin: 1.5rem 5rem 4rem;
	text-align: center;
	line-height: 2.1rem;
`;

export const Stores = styled.div`
	${flex({ fd: 'column' })}
`;

export const AnchorStyled = styled.a`
	display: block;
	margin-bottom: 2rem;
`;
