// Import helpers
import { size } from 'components/styles';

export const getSlidesToShow = ({ large, small, medium }) => {
	const { innerWidth } = window;

	let slidesToShow = large;

	const isMobile = innerWidth < size.small;
	const isTablet = innerWidth >= size.small && innerWidth < size.medium;
	const isDesktop = innerWidth >= size.medium;

	if (isMobile) {
		slidesToShow = small;
	} else if (isTablet) {
		slidesToShow = medium;
	}

	return {
		slidesToShow,
		isDesktop
	};
};
