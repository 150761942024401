import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	z-index: 9999;

	${({ isFloatMode }) =>
		isFloatMode
			? css`
					right: 1rem;
					bottom: 1rem;
			  `
			: css`
					top: 7.6rem;
					right: 3.4rem;
			  `}
`;
