import React from 'react';
import { bool, number, object, string } from 'prop-types';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { checkProductSubtype, getTileMetadata, joinArray } from 'helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import styles
import { Root, Image, TitleText } from './styles';

const { TITLE } = PRODUCT_IMAGES_TYPES;

const Title = ({
	images,
	metadata,
	title,
	subtype,
	hideTitle,
	isCarousel = false,
	context
}) => {
	const imageUrl = getImage({ images, type: TITLE });

	const isTitleHidden = metadata?.hide_title || hideTitle;
	const titleMetadata = getTileMetadata(context);

	const { isArticle } = checkProductSubtype(subtype);
	const isLightBackground = !isCarousel && isArticle;
	const titleElements = joinArray(
		[titleMetadata, metadata?.title ?? title],
		' '
	);

	return (
		<Root imageUrl={imageUrl} isArticle={isArticle}>
			{
				// Render title image. If image is missing, render title text if it's not deactivated.
				//Otherwise don't render anything
				imageUrl ? (
					<Image imageUrl={imageUrl} />
				) : (
					!isTitleHidden && (
						<TitleText isLightBackground={isLightBackground}>
							{titleElements}
						</TitleText>
					)
				)
			}
		</Root>
	);
};

Title.propTypes = {
	images: object,
	metadata: object,
	title: string,
	subtype: string,
	hideTitle: number,
	isCarousel: bool,
	context: object
};

export default Title;
