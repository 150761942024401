import React, { createContext, useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';

// Import actions
import { authorizeUser } from 'store/actions';

// Import utilities
import { useCookies } from 'components/utilities';

// Import helpers
import { openNotification } from 'components/utilities/notification/notification';
import { EMAIL, getUserDetails } from './helpers';

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// ---- state ----
	const [isLoading, setIsLoading] = useState(false);
	const [authProvider, setAuthProvider] = useState('');

	const {
		formRef,
		showCookieMessage,
		formikSubmit,
		checkCookiesAccepted,
		setShowCookieMessage
	} = useCookies();

	const handleSetLoader = (status) => setIsLoading(status);

	const handleClearAuthProvider = () => setAuthProvider('');

	const handleAcceptCookiesCallback = () => {
		// submit firebase authentication
		if (authProvider) {
			return handleFirebaseAuthSubmit(authProvider);
		}

		// submit login form
		return formikSubmit();
	};

	const handleFirebaseAuthButtonClick = (authProvider) => {
		const isCookiesAccepted = checkCookiesAccepted();

		// submit firebase authentication
		if (isCookiesAccepted) {
			return handleFirebaseAuthSubmit(authProvider);
		}

		// set firebase auth provider to use in handleAcceptCookiesCallback
		return setAuthProvider(authProvider);
	};

	const handleFirebaseAuthSubmit = async (authProvider) => {
		try {
			// set loading
			setIsLoading(true);

			// init authentication
			const AuthProvider = firebase.auth[authProvider];
			const provider = new AuthProvider();

			// add scope
			provider.addScope(EMAIL);

			// firebase signIn
			const { user, additionalUserInfo } = await firebase
				.auth()
				.signInWithPopup(provider);

			// get user id token
			const idToken = await user.getIdToken();

			// prepare user details
			const userDetails = getUserDetails({ user, additionalUserInfo });

			// dispatch user authorization
			await authorizeUser({ idToken, userDetails })(dispatch);

			// turn off loading
			setIsLoading(false);
		} catch (error) {
			// turn off loading
			setIsLoading(false);
			// open notification
			openNotification({
				type: 'error',
				title: t('common_general_error'),
				description: t('common_login_firebase_error')
			});
		}
	};

	return (
		<AuthenticationContext.Provider
			value={{
				isLoading,
				setLoader: handleSetLoader,
				formRef,
				showCookieMessage,
				checkCookiesAccepted,
				setShowCookieMessage,
				firebaseAuthButtonClick: handleFirebaseAuthButtonClick,
				acceptCookiesCallback: handleAcceptCookiesCallback,
				clearAuthProvider: handleClearAuthProvider
			}}
		>
			{children}
		</AuthenticationContext.Provider>
	);
};

AuthenticationProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default AuthenticationProvider;
