import { useMediaQuery } from 'react-responsive';

// Import helpers
import { getUserAgentInfo } from 'helpers';

// Import variables
import { MOBILE_DEVICES } from 'helpers/variables';

const useDeviceInfo = (query = MOBILE_DEVICES) => {
	const isTabletOrMobile = useMediaQuery({
		query
	});

	const { isMobile } = getUserAgentInfo();

	const isMobileDevice = isMobile || isTabletOrMobile;

	return { isMobileDevice };
};

export default useDeviceInfo;
