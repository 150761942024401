import styled, { css } from 'styled-components';

// Import styles
import { flex, customMinResponsive, size } from 'components/styles';

export const Image = styled.img`
	height: 3.8rem;
	filter: grayscale(${({ isContrast }) => (isContrast ? 1 : 0)});

	${customMinResponsive(
		size.medium,
		css`
			height: 6rem;
			margin-top: 0.4rem;
		`
	)}
`;

export const Button = styled.button`
	border: none;
	background: transparent;
	cursor: pointer;
	margin-right: 2.35rem;
	${flex({ ai: 'start' })};

	&:focus-visible {
		outline: 2px dashed ${({ theme }) => theme.outline.focusColor};
	}
`;
