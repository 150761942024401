import React from 'react';
import {
	Document as PDFDocument,
	Page,
	Text,
	View,
	Font,
	Image
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

// Import assets
import logo from 'assets/images/odeon_logo.png';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { removeHtmlTags, fontOptions } from './helpers';

const { REACT_APP_WEBSITE_URL } = process.env;

// Register Font
Font.register(fontOptions);

const Document = ({ title, content }) => {
	const {
		i18n: { language }
	} = useTranslation();

	const classes = useStyles();

	const documentContent = removeHtmlTags(content);

	return (
		<PDFDocument language={language}>
			<Page style={classes.page}>
				<View style={classes.view}>
					<Image src={logo} style={classes.logo} />
					<Text style={classes.header}>{title}</Text>
					<Text style={classes.content}>{documentContent}</Text>
				</View>
				<Text fixed style={classes.footer}>
					{REACT_APP_WEBSITE_URL}
				</Text>
			</Page>
		</PDFDocument>
	);
};

Document.propTypes = {
	title: string,
	content: string
};

export default Document;
