import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import {
	flex,
	customMinResponsive,
	size,
	customResponsive
} from 'components/styles';

// Import icons
import { ReactComponent as ArrowRightSVG } from 'assets/icons/ic_payment_arrow.svg';

export const Container = styled.div`
	position: relative;
	text-align: center;
	${flex({ jc: 'space-between', fd: 'column' })}

	${customResponsive(
		size.medium,
		css`
			flex-direction: row-reverse;
			height: 5.8rem;
			padding-left: 1.8rem;

			&:nth-of-type(even) {
				border-top: 1px solid;
				border-bottom: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColorMobile};
			}
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			min-height: 3rem;
			width: 37rem;
			padding: 4rem 0 2.5rem 0;
			flex: 1 1 0px;

			&:nth-of-type(even) {
				border-left: 1px solid;
				border-right: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColor};
			}
		`
	)}
`;

export const Name = styled.p`
	font-size: ${({ theme }) =>
		theme.fontSize.payment.paymentOptions.name.mobile}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.name.color};
	line-height: 1.2;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.payment.paymentOptions.name.desktop}rem;
			display: ${({ isDesktop }) => (!isDesktop ? 'none' : 'block')};
			line-height: 3.6rem;
		`
	)}

	${customResponsive(
		size.medium,
		css`
			display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};
			flex: 1;
			text-align: left;
			padding-left: 2.3rem;
			font-weight: ${({ theme }) => theme.font.normal};
		`
	)}
`;

export const Info = styled.p`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.payment.paymentOptions.info.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.info.fontWeight};
	margin-bottom: 2rem;
`;

export const Price = styled.span`
	font-size: ${({ theme }) =>
		theme.fontSize.payment.paymentOptions.price.mobile}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.price.color};
	display: inline-block;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.payment.paymentOptions.price.desktop}rem;
			margin-top: 0.8rem;
			margin-bottom: 2.1rem;
			line-height: 2.8rem;
		`
	)}
`;

export const Bottom = styled.div`
	text-align: center;
`;

export const PromotionPrice = styled.strong`
	color: ${({ theme }) => theme.payment.paymentOptions.promotionPrice.color};
	margin-left: 1rem;
`;

export const ButtonStyled = styled(Button)`
	width: 16.8rem;
	padding: 0.8rem 0;
	display: none;

	${customMinResponsive(
		size.medium,
		css`
			display: block;
		`
	)};
`;

export const ArrowButton = styled(Button)`
	padding: 1.8rem;
	border-radius: 0;
	margin-left: 1.5rem;

	&:hover {
		background-color: ${({ theme }) => theme.gray};
	}

	${customMinResponsive(
		size.medium,
		css`
			display: none;
		`
	)}
`;

export const FlexContainer = styled.div`
	width: ${({ isOtherBluemedia }) => (isOtherBluemedia ? '100%' : 'auto')};

	${customResponsive(
		size.medium,
		css`
			${flex()}
		`
	)}
`;

export const IconArrowRight = styled(ArrowRightSVG)`
	svg {
		path {
			width: 8.25px;
			height: 1.45rem;
		}
	}
`;
