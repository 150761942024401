import styled, { css } from 'styled-components';

// Import styles
import { textEllipsis, customMinResponsive, size } from 'components/styles';

export const Root = styled.div`
	z-index: 2;
	display: flex;
	align-items: center;
	margin-bottom: ${({ isArticle }) => (isArticle ? 'unset' : '0.6rem')};

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 2.3rem;
		`
	)}

	${({ imageUrl }) =>
		imageUrl &&
		css`
			height: 9rem;
		`}
`;

export const Image = styled.div.attrs(({ imageUrl }) => ({
	style: {
		backgroundImage: `url(${imageUrl})`
	}
}))`
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center left;
	background-size: contain;
	width: 100%;
	height: 100%;
`;

export const TitleText = styled.h1`
	font-size: ${({ theme }) => theme.fontSize.product.media.title}rem;
	line-height: 2.5rem;
	margin-bottom: 2.2rem;
	color: ${({ theme }) => theme.white};
	${textEllipsis}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xl}rem;
			line-height: 5.7rem;
			margin-bottom: 2.5rem;
		`
	)}
`;
